<div class="select-dropdown dd-dropdown-filter-expansion">
  <mat-accordion class="filter-accordion" multi>
    <mat-expansion-panel [expanded]="true" class="filter-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span class="filter-title">{{dropdownTitle}}</span>
          <div class="clear-container">
            <div class="filter-clear" (click)="onClear();$event.stopPropagation()">
              Clear
            </div>
            <div class="tmi icon tmi-arrow-half-right"></div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field appearance="outline" class="interval dd-mat-mdc-select-form-field">
        <mat-select panelClass="dd-mat-mdc-select" #select [(value)]="defaultSelected"
          (selectionChange)="setSelectedValue($event)" placeholder="Select">
          <mat-option *ngFor="let interval of dropdownData" [value]="interval">
            {{interval?.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="requiredMsg && defaultSelected ===''">{{requiredMsg}}</mat-error>
      <mat-error *ngIf="!requiredMsg && dropdownData.length === 0">{{noDataMessage}}</mat-error>

    </mat-expansion-panel>
  </mat-accordion>
</div>