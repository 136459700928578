import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { COMPANY_TYPE, DATA_VALIDATION_MEGS, HIERARCHY_TYPE, lexusReports, toyotaReports } from 'src/app/constants/constants';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsService } from '../../services/custom-report.service';
import { formattedResponseForCompanySelect, formattedResponseForHierarchySelect } from '../../utils/common-utils';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  @Input() menuTemplate: TemplateRef<any>;
  @Input() title: string = '';
  @Input() sideNav: MatSidenav;
  @Input() requiredFilters;
  @Input() reportId;
  @Input() searchFilterObject;
  @Output() searchChips: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() searchFilter: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() selectedDealers: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
  requiredMsg: string = '';
  public expandedSideNav: boolean = true;
  companyDropdownData: any = [];
  hierarchyDropdownData: any = [];
  readonly subscription: Subscription = new Subscription();
  dealersResp: any;
  hierarchyResp: any = [];
  selectedCompanyVal: any;
  dateFilterResp: any = [];
  selectedHrchy: any;
  isMultiSelectDisabled: boolean = false;
  columnsToShowForMultiSelect = [];
  headersToShow = [];
  hierarchyAPIResp: any;
  hierarchyMembersResp: any;
  hierarchyTypeResp: any;
  filtersResp: any;
  private _removedFilteredKey;
  formattedHierarchyData: any;
  noDataMsg: string;

  get removedFilterKey() {
    return this._removedFilteredKey;
  }
  @Input() set removedFilterKey(value: any) {
    this._removedFilteredKey = value;
    if (this._removedFilteredKey?.key === 'company') {
      this.clearFilterKey(this._removedFilteredKey?.key);
    }

  }

  constructor(public customReportsApiService: CustomReportsApiService,
    public filterService: CustomReportsFiltersService,
    private readonly spinnerService: SpinnerService,
    private customReportsService: CustomReportsService,
    public changeDetector: ChangeDetectorRef,

  ) { }

  ngOnInit() {
    this.getDlrHrcyMembersList();
    this.getRequiredFilterCalls();
  }

  getRequiredFilterCalls() {
    if (this.requiredFilters?.dateFilter && this.requiredFilters?.companyDropdownFilter && this.selectedCompanyVal && this.dateFilterResp?.length === 0) {
      this.getDate();
    }
    if (this.requiredFilters?.dateFilter && !this.requiredFilters?.companyDropdownFilter && this.dateFilterResp?.length === 0) {
      this.getDate();
    }
    if (!this.requiredFilters?.hierarchyDropdownFilter && this.hierarchyDropdownData?.length > 0) { //  No hierarchyFilter in Required Filters
      this.selectedHrchy = this.hierarchyDropdownData.find(x => x.id === HIERARCHY_TYPE.DEALER)?.id;
    }
    if (!this.requiredFilters?.companyDropdownFilter && this.companyDropdownData?.length > 0) {  //  No companyFilter in Required Filters
      if (toyotaReports.includes(this.reportId)) {
        this.selectedCompanyVal = COMPANY_TYPE.TOYOTA;
      } else if (lexusReports.includes(this.reportId)) {
        this.selectedCompanyVal = COMPANY_TYPE.LEXUS;
      }
      this.getFilteredHierarchyDrodownData();
    }
    if (this.requiredFilters?.companyDropdownFilter) {
      this.getFilteredHierarchyDrodownData();
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.hierarchyDropdownFilter) {
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.multiSelectDealerMemberFilter) {
      this.columnsToShowForMultiSelect = ['MEMBER_CODE', 'MEMBER_NAME'];
      this.getFilteredHierarchyMember();
    }
    if (this.requiredFilters?.multiSelectHierarchyMemberFilter) {
      this.getHierachyMemberHeader();
      this.getFilteredHierarchyMember();
    }
  }

  public handleSideNavToggle(): void {
    this.expandedSideNav = !this.expandedSideNav;
    this.sideNav?.toggle();
  }

  public updateSearchInfo(event): void {
    this.searchChips.emit(event);
    if (event?.key === 'company') {
      this.selectedHrchy = '';
      this.selectedCompanyVal = event.value.id;
      this.getRequiredFilterCalls();
    }
    else if (event?.key === "hierarchy") {
      this.selectedHrchy = event.value.id;
      this.getRequiredFilterCalls();
    }
  }


  getDlrHrcyMembersList() {
    const hmData = sessionStorage.getItem('filter-hierarchy-data');
    if (hmData) {
      this.filtersResp = JSON.parse(hmData);
      this.getFilteredData();
    } else {
      this.spinnerService.displaySpinner();
      this.subscription.add(this.customReportsApiService.getDlrAndHrcyMemData(this.selectedCompanyVal, this.selectedHrchy).subscribe((resp => {
        if (resp.status === 200) {
          this.filtersResp = resp?.body;
          if (this.filtersResp?.HierarchyMemberData?.length > 0) {
            sessionStorage.setItem('filter-hierarchy-data', JSON.stringify(this.filtersResp));
          }
          this.spinnerService.hideSpinner();
          this.getFilteredData();
        }
      }
      ),
        (err) => {
          console.log(err, 'errorrrrrrrrrr')
          this.spinnerService.hideSpinner();

        }
      )
      )
    }
  }

  getFilteredData() {
    this.companyDropdownData = this.filtersResp?.CompanyData ? formattedResponseForCompanySelect(this.filtersResp?.CompanyData) : [];
    this.hierarchyResp = this.filtersResp?.HierarchyMemberData ? _.clone(this.filtersResp?.HierarchyMemberData) : [];
    this.formattedHierarchyData = this.filtersResp?.HierarchyData ? formattedResponseForHierarchySelect(this.filtersResp?.HierarchyData) : [];
    this.hierarchyDropdownData = _.uniqBy(this.formattedHierarchyData, 'id');;
    if (this.reportId === 98) {
      this.hierarchyDropdownData = this.hierarchyDropdownData.filter(ele => ele.name === 'District');
    }
    this.changeDetector.detectChanges();
  }

  getFilteredHierarchyMember() {
    if (this.filtersResp?.HierarchyMemberData?.length > 0) {
      this.isMultiSelectDisabled = false;
      if (this.selectedHrchy && this.selectedCompanyVal) {
        this.hierarchyResp = _.filter(this.filtersResp?.HierarchyMemberData, { COMPANY_ID: this.selectedCompanyVal, LEVEL_ID: this.selectedHrchy });
      } else if (this.selectedHrchy) {
        this.hierarchyResp = _.filter(this.filtersResp?.HierarchyMemberData, { LEVEL_ID: this.selectedHrchy });
      } else if (this.selectedCompanyVal) {
        this.hierarchyResp = _.filter(this.filtersResp?.HierarchyMemberData, { COMPANY_ID: this.selectedCompanyVal });
      }
    }
  }


  getDate(): void {
    this.spinnerService.displaySpinner();
    this.subscription.add(this.customReportsApiService.getDateAndMonth(this.reportId, this.selectedCompanyVal).subscribe((resp => {
      if (resp.status === 200) {
        this.spinnerService.hideSpinner();
        this.dateFilterResp = resp.body;
        if(this.dateFilterResp?.length === 0) {
          this.noDataMsg = DATA_VALIDATION_MEGS.NO_DATA_FOUND;
        }
      }
    }), (err) => {
      console.log(err, 'errrrrrrrrr');
      this.spinnerService.hideSpinner();
    }));
  }
  getHierachyMemberHeader() {
    if (this.requiredFilters?.multiSelectDealerMemberFilter) {
      this.columnsToShowForMultiSelect = ['MEMBER_CODE', 'MEMBER_NAME'];
    } else {
      if (this.selectedHrchy === HIERARCHY_TYPE.REGION) {
        this.columnsToShowForMultiSelect = ['REGION_CODE', 'MEMBER_NAME']
      } else {  // for district(11) and dealer(10)           
        this.columnsToShowForMultiSelect = ['REGION_CODE', 'DISTRICT_CODE', 'MEMBER_CODE', 'MEMBER_NAME'];
      }
      if (this.selectedHrchy === HIERARCHY_TYPE.DEALER) {
        this.headersToShow = ['Rgn.', 'Dstr', 'Dlr', 'Dealer name']
      } else {  //// for district(11) and region(12)
        this.headersToShow = [];
      }
    }
  }

  getFilteredHierarchyDrodownData() {
    if (this.selectedCompanyVal && !this.selectedHrchy) {
      this.hierarchyDropdownData = _.filter(this.formattedHierarchyData, { COMPANY_ID: this.selectedCompanyVal });
    }
  }
  clearFilterKey(key) {
    if (key === 'company') {
      this.hierarchyDropdownData = [];
      this.hierarchyResp = [];
      this.dealersResp = [];
      this.selectedCompanyVal = '';
      this.selectedHrchy = '';
      this.dateFilterResp = [];
      this.columnsToShowForMultiSelect = [];
      this.changeDetector.detectChanges();
      this.getHierachyMemberHeader();
      this.getFilteredData();
      this.getFilteredHierarchyDrodownData();
    }
    this.clearFilter.emit(key);
  }

  public updateFilterSeletedDealers(event): void {
    this.selectedDealers.emit(event);
  }

  public fetchResult(): void {
    if (Object.keys(this.requiredFilters).length === this.searchFilterObject?.length) {
      this.searchFilter.emit();
    } else {
      this.requiredMsg = DATA_VALIDATION_MEGS.REQUIRED_FIELD_MSGS;
    }
  }

}
