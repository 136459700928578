import { Component, OnInit } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { SpinnerService } from '../../../../services/spinner.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { TCUVDealerAtGlanceReport } from '../reportConstants';
import { CustomReportsService } from '../../services/custom-report.service';
import { ToastService } from 'src/app/services/toast.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import { Router } from '@angular/router';
import { DATA_FORMAT_FLAGS, reportUrlType } from 'src/app/constants/constants';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-tcuv-dealer-at-glance',
  templateUrl: './tcuv-dealer-at-glance.component.html',
  styleUrls: ['./tcuv-dealer-at-glance.component.scss']
})
export class TCUVDealerAtGlanceComponent implements OnInit {


  profileHeader: string = 'PROFILE';
  showDepartmentTable: boolean = true;
  public tcuvDealerAtGlanceJsonObj;
  public currentTabIndex: number = 0;
  public selectedFilter = [];
  public tabList: string[] = [];
  public obj = Object.values;
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isProfileTable: boolean = false;
  public sources: Observable<any>[] = [];
  public genericProfileTableDataSource;
  public selectedTabIndex = [0];
  public reportId: number;
  public previousFilter;
  public isFilterSame: boolean;

  constructor(
    private readonly customReportsApiService: CustomReportsApiService,
    private readonly spinnerService: SpinnerService,
    public route: Router,
    private readonly customReportsService: CustomReportsService,
    private filterService: CustomReportsFiltersService,
    public toast: ToastService
  ) {
    this.tabList = TCUVDealerAtGlanceReport.TCUVDealerAtGlanceTabNames
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.gettcuvDealerAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }

  public async getTabDataSource(tabIndex: number) {
    const hierarchyObj = this.selectedFilter.find(x => x.HIERARCHY_ID);
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy_level_member_rcid: obj['dealer'].MEMBER_CODE,
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getTCUVProfileInfo(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 1) {
      this.sources = [this.customReportsApiService.getTCUVNVSSeriesCertsInfoSeries(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getTCUVNVSSeriesCertsInfoSourceofsales(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getTCUVNVSSeriesCertsInfoComp(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      this.customReportsApiService.getTCUVNVSSeriesCertsInfoAgedunits(requestObj).pipe(this.customReportsService.handleError()?.bind(this))
      ]
    } else if (tabIndex === 2) {
      this.sources = [this.customReportsApiService.getTCUVFinancialReportData(requestObj).pipe(this.customReportsService.handleError()?.bind(this))
      ]
    }
    else {

    }
    return this.sources;
  }

  public async mapResponse() {
    //when we have datasource and navigating back to same tab without change in filter do not make api call
    if (this.tcuvDealerAtGlanceJsonObj?.tabList[this.currentTabIndex]?.datasource?.length === 0) {
      let currentTableResponse = [];
      await this.getTabDataSource(this.currentTabIndex).catch(err => console.warn('err', err))
      this.subscription.add(forkJoin(this.sources).subscribe({
        next: (resp) => {
          this.customReportsService.setPdfExcelIcons(true);
          // resp.forEach((item,index) => console.log(`response from source ${index}`,item));
          let profileObj;
          let series_report;
          let source_of_sale;
          let complaince_report;
          let aged_units;

          if (this.currentTabIndex === 0) {
            if (resp[0]?.status === 200 && resp[0]?.body)
              profileObj = [{
                "datasourceName": "profileDataSource",
                "tableData": resp[0]?.body,
                "isRegionalTable": false,
              }]
          } else if (this.currentTabIndex === 1) {
            if (resp[0]?.status === 200 && resp[0]?.body)
              series_report = [{
                "tableData": resp[0]?.body,
                "tableHeader": TCUVDealerAtGlanceReport.SeriesReportRecHeader,
                "datasourceName": "series_report",
                "tableHeaderClass": "dxfw-tcuv-header",
                "removeLineBreak": true,
                "printZeros": true,
                'dataFormattingColumns': '',
                'pUnitsFlag': false,
                'removeParentsForNegativeVal': false,
                "setStylesForColumns": [{ columns: ['SERIES_NAME'], styles: { textAlign: 'left', paddingLeft: '2px' } }, { columns: 'default', styles: { textAlign: 'center' } }],
              }]
            if (resp[1]?.status === 200 && resp[1]?.body)
              source_of_sale = [{
                "tableData": resp[1]?.body,
                "tableHeader": TCUVDealerAtGlanceReport.SourceOfSalesHeader,
                "tableSubHeader": TCUVDealerAtGlanceReport.SourceOfSalesSubHeader,
                "datasourceName": "source_of_sale",
                "tableHeaderClass": "dxfw-tcuv-header",
                "removeLineBreak": true,
                "dataFormattingColumns": 'all',
                "defaultColumnsUnitFlag": [
                  { columns: ["PERCENTAGE_TOTAL_MTD", "PERCENTAGE_TOTAL_YTD"], unitFlag: DATA_FORMAT_FLAGS.PercentWithUnknownDecimal }]

              }]

            if (resp[2]?.status === 200 && resp[2]?.body)
              complaince_report = [{
                "tableData": resp[2]?.body,
                "tableHeader": TCUVDealerAtGlanceReport.ComplainceReportHeader,
                "tableSubHeader": TCUVDealerAtGlanceReport.ComplainceReportSubHeader,
                "datasourceName": "complaince_report",
                "tableHeaderClass": "dxfw-tcuv-header",
                "removeLineBreak": true,
                "printZeros": true,
                "dataFormattingColumns": 'all',
                "setStylesForColumns": [ { columns: 'default', styles: { textAlign: 'center' } }],
                "defaultColumnsUnitFlag": [
                  { columns: ["PERCENTAGE_TOTAL_MTD", "PERCENTAGE_TOTAL_YTD"], unitFlag: DATA_FORMAT_FLAGS.PercentWithUnknownDecimal }]


              }]

            if (resp[3]?.status === 200 && resp[3]?.body)
              aged_units = [{
                "tableData": resp[3]?.body,
                "tableHeader": TCUVDealerAtGlanceReport.agedUnitsHeader,
                "datasourceName": "aged_units",
                "tableHeaderClass": "dxfw-tcuv-header",
                "removeLineBreak": true,
                "setStylesForColumns": [{ columns: ['KPI'], styles: { textAlign: 'left', paddingLeft: '2px' } }, { columns: 'default', styles: { textAlign: 'center' } }],
              }]
          } else if (this.currentTabIndex === 2) {
            let reportData = resp[0]?.body[0]?.reportData;
            if (reportData?.length > 0) {
              let tableObj;
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  tableObj = [{
                    "datasourceName": `tcuvfinance`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                    "removeLineBreak": true,
                    "dataFormattingColumns": 'all',
                    "setStylesForColumns": [{ columns: ['ACCT_NAME'], styles: { width: '150px', textAlign: 'left', borderRight: '2px solid grey', paddingLeft: '2px' } }, { columns: 'default', styles: { width: '80px' } },
                    { columns: ['MTD_PCTCHG', 'DLR_YTD_PCT_CHG'], styles: { width: '80px', borderRight: '2px solid grey' } }],
                    "defaultColumnsUnitFlag": [
                      { columns: ["DIST_YTD_PCT_CHG", "REG_YTD_PCT_CHG", "NAT_YTD_PCT_CHG", "MTD_PCTCHG", "DLR_YTD_PCT_CHG"], unitFlag: DATA_FORMAT_FLAGS.PercentWithUnknownDecimal }],
                    'removeParentsForNegativeVal': true,
                    "printZeros": true
                  }];

                  if (index === 0) {
                    tableObj[0]['tableHeader'] = TCUVDealerAtGlanceReport.financialHeader,
                      tableObj[0]['tableSubHeader'] = TCUVDealerAtGlanceReport.financialSubHeader;
                    tableObj[0]['columnHeaderName'] = this.obj(resp[0]?.body[0]?.reportHeaderData[0]);
                    tableObj[0]['hideColumnHeader'] = false;
                  } else {
                    tableObj[0]['hideColumnHeader'] = true;
                  }
                }
                currentTableResponse.push(tableObj);
              });
            }
          }
          if (series_report) {
            currentTableResponse.push(series_report)
          }
          if (source_of_sale) {
            currentTableResponse.push(source_of_sale)
          }
          if (complaince_report) {
            currentTableResponse.push(complaince_report)
          }
          if (aged_units) {
            currentTableResponse.push(aged_units)
          }
          if (profileObj) {
            currentTableResponse.push(profileObj)
          }
          if (currentTableResponse.length !== 0) {
            this.tcuvDealerAtGlanceJsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
          }
          this.previousFilter = cloneDeep(this.selectedFilter);
          //assign the current filter value
          this.spinnerService.hideSpinner();
        }, error: (err) => {
          console.warn('Error handler: mapping response', err);
          this.spinnerService.hideSpinner();
        }
      })
      )
    }
    else {
      this.spinnerService.hideSpinner();
    }

  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilter);
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
  }

  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilter = filteredValues;
          this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
          if (this.isFilterSame === false) {
            this.selectedTabIndex = [];
            this.selectedTabIndex.push(this.currentTabIndex);
          }
          if (this.tcuvDealerAtGlanceJsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
            this.gettcuvDealerAtGlanceReport(); //initialise to empty object
          }
          this.mapResponse();
        }
      }, error: (error) => {
        console.warn('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }

  ngOnDestroy(): void {
    this.filterService.setSelectedValues([]);
    this.subscription?.unsubscribe();
    this.filtersSubscription?.unsubscribe();
    this.customReportsService.setPdfExcelIcons(false);

  }

  public displayProfileSection() {
    if (this.currentTabIndex === 0) {
      this.isProfileTable = true;

    } else {
      this.isProfileTable = false;
    }
  }

  public gettcuvDealerAtGlanceReport() {
    this.tcuvDealerAtGlanceJsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        }
      ]
    }
  }

}



