import { COMPANY_TYPE, errorMessages, HIERARCHY_TYPE, reportId } from "src/app/constants/constants";
import { IMAGE_CONTENT } from 'src/app/constants/imageContent';

export function getObjFromFilteredArray(selectedFilter: any) {
  const obj = Object.fromEntries(selectedFilter.map(item => [item.key, item.value]));
  return obj;
}
export function getMonthAndYear(date) {
  let dateobj = new Date(date);
  const searchMonth = (dateobj.getMonth() + 1).toString();
  const searchYear = dateobj.getFullYear().toString();
  return { searchMonth, searchYear }
}
export function formattedResponseForCompanySelect(data) {
  const filteredData = data.filter(x => x.COMPANY_ID !== null && x.COMPANY_ID !== COMPANY_TYPE.BOTH);
  const formatedData = filteredData.map(val => {
    return {
      id: val.COMPANY_ID,
      name: val.COMPANY_NAME
    }
  })
  return formatedData;
}
export function formattedResponseForHierarchySelect(data) {
  const formatedData = data.map(val => {
    return {
      id: val.LEVEL_ID,
      name: val.LEVEL_NAME,
      COMPANY_ID: val.COMPANY_ID
    }
  })
  return formatedData;
}
export function getMonthNameFromNumber(month) {
  const d = new Date();
  d.setMonth(month - 1);
  const monthName = d.toLocaleString("default", { month: "long" });
  return monthName;
}

export function getImageFromBase64(companyCode) {
  let imgpath;
  if (companyCode === COMPANY_TYPE.TOYOTA) {
    imgpath = IMAGE_CONTENT.TOYOTA_FINANCIAL_SERVICES;
  } else if (companyCode === COMPANY_TYPE.LEXUS) {
    imgpath = IMAGE_CONTENT.LEXUS_FINANCIAL_SERVICES;
  }
  return imgpath;
}

export function getReportTitleToDisplay(obj: any) {
  let reportTitleToDisplay;
  if (obj['hierarchy']?.id === HIERARCHY_TYPE.DEALER) {   // 10 - Dealer
    reportTitleToDisplay = (obj['dealer'].MEMBER_NAME).split(' - ').slice(-1)[0] + ' - ' + obj['dealer'].MEMBER_CODE;
  } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.DISTRICT) {  // 11 - District
    reportTitleToDisplay = obj['dealer'].MEMBER_NAME
  } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.REGION) {  // 12 - Region
    reportTitleToDisplay = (obj['dealer'].MEMBER_NAME).split(' - ').slice(-1)[0] + ' - ' + obj['dealer'].REGION_CODE;
  } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.DSSO) {
    reportTitleToDisplay = (obj['dealer'].MEMBER_NAME).split(' - ').slice(-1)[0] + ' - ' + obj['dealer'].REGION_CODE;
  } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.AREA) {
    reportTitleToDisplay = (obj['dealer'].MEMBER_NAME).split(" ").slice(0, -1).join(" ") + ' - ' + obj['dealer'].REGION_CODE;
  } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.NATIONAL) {   // 13 - National
    reportTitleToDisplay = 'TOYOTA FINANCIAL SERVICES';
  }
  return reportTitleToDisplay;
}

export function getLogosForDealers(companyCode) {
  let rightLogoPath = '';
  if (companyCode === COMPANY_TYPE.TOYOTA) {
    rightLogoPath = IMAGE_CONTENT.TOYOTA_QUALITY_FINANCIAL_MANAGEMENT
  } else if (companyCode === COMPANY_TYPE.LEXUS) {
    rightLogoPath = IMAGE_CONTENT.LEXUS_QUALITY_FINANCIAL_MANAGEMENT;
  }
  return rightLogoPath;
}


export function getLogosForReports(report_id) {
  let rightLogoPath = '';
  if (report_id === reportId.LEXUS_PO_SNAPSHOT) {
    rightLogoPath = IMAGE_CONTENT.LEXUS_PO_SNAPSHOT_EXEC_SUMMARY;
  }
  return rightLogoPath;
}

export function getErrorMessage(error, isRetry) {
  let msg = '';
  if (Object.keys(errorMessages).includes(String(error.status))) {
    msg = errorMessages[error.status];
  } else {
    msg = errorMessages['100'];
  }
  if (isRetry) {
    msg = msg + '. Retrying again...';
  } else {
    msg = msg + '. Please try again later.';
  }
  return msg;
}