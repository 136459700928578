import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { COMPANY_TYPE, HIERARCHY_TYPE, REPORT_MSGS, reportUrlType } from 'src/app/constants/constants';
import {
  IMAGE_CONTENT
} from 'src/app/constants/imageContent';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getImageFromBase64, getLogosForDealers, getMonthAndYear, getObjFromFilteredArray, getReportTitleToDisplay } from '../../utils/common-utils';

@Component({
  selector: 'app-national-dealer-performance-financial-services',
  templateUrl: './nat-dealer-per-fin-services.component.html',
  styleUrls: ['./nat-dealer-per-fin-services.component.scss']
})
export class NationalDealerPerformanceFinancialServicesComponent implements OnInit {

  public currentTabIndex: number = 0;
  public selectedFilter = [];
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  public nationalDealerPerformanceFinancialServicesjsonObj;
  public sources: Observable<any>[] = [];
  public obj = Object.values;
  public selectedYear;
  // selectedDealerCode: string;
  public noDataToDisplayMsg: string;
  public reportTitleToDisplay: string;
  public reportId: number;
  public companyCode: number;
  public hierarchy: number;
  public dealerId: number;
  public dealerName: number;
  public imgPath: string;
  public rightLogoPath: string;
  public reportTitle: string = '';
  public IsDealerHierarchy: boolean = false;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-list'));
    this.reportTitle = reportList?.[this.reportId]?.name;
    this.getNationalDealerPerformanceFinancialServicesReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
  }


  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.companyCode = obj['company'].id;
    this.hierarchy = obj['hierarchy'].id;
    this.dealerId = obj['dealer'].MEMBER_CODE;
    this.dealerName = obj['dealer'].MEMBER_NAME;
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      view_id: 'dealer_performance',
      comp_code: obj['company'].id,
      hierarchy: obj['hierarchy'].id,
      hierarchy_member_code: obj['dealer'].MEMBER_CODE,
    }
    this.reportTitleToDisplay = getReportTitleToDisplay(obj);

    this.sources = [this.customReportsApiService.getNationalDealerPerformanceFinancialServicesData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          if (filteredValues?.length > 0) {
            this.spinnerService.displaySpinner();
            this.selectedFilter = filteredValues;
            this.mapResponse();
          }

        }, error: (error) => {
          console.warn('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
    this.customerReportService.setPdfExcelIcons(false);
  }

  public getNationalDealerPerformanceFinancialServicesReport() {
    this.nationalDealerPerformanceFinancialServicesjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    let tableSubHeaderColumns = [];
    await this.getTabDataSource();
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          this.customerReportService.setPdfExcelIcons(true);
          this.imgPath = getImageFromBase64(this.companyCode);
          if (this.hierarchy === HIERARCHY_TYPE.DEALER) {
            this.rightLogoPath = getLogosForDealers(this.companyCode);
          }
          tableSubHeaderColumns = this.getTableSubHeaderColumns();
          let reportData = resp[0]?.body;
          if (reportData?.length > 0) {
            reportData.forEach((ele, index) => {
              if (ele.sectionData?.length > 0) {
                const obj = [{
                  "datasourceName": `DealerPerformanceDatasource ${index}`,
                  "tableData": ele.sectionData,
                  "sectionTitle": ele.SECTIONTITLE,
                  "sectionId": ele.ROW,
                  "removeLineBreak": true,
                  'showLineNumbers': true,
                  'showIndicator': this.hierarchy === HIERARCHY_TYPE.DEALER ? true : false,
                  'dataFormattingColumns': 'all',
                  'isColumnBorder': ['NEW_CURR_MTD', 'USED_CURR_MTD'],
                  "setStylesForColumns": [{ columns: ['ACCT_TITLE'], styles: { textAlign: 'left', paddingLeft: '2px' } }, { columns: 'default', styles: { textAlign: 'center' } }],

                }];
                if (index === 0) {
                  obj[0]['tableHeader'] = tableSubHeaderColumns;
                }
                currentTableResponse.push(obj);
              }

            });
          }
          else {
            this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
          }
        }


        this.nationalDealerPerformanceFinancialServicesjsonObj.datasource = currentTableResponse;

        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.warn('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getTableSubHeaderColumns() {
    return [{
      " ": '1',
      "NO-NAME": '1',
      "NEW VEHICLE": '3',
      "USED VEHICLE": '3',
      "COMBINED VEHICLE": '3'
    }]
  }

}



