import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { HIERARCHY_TYPE, reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CRGridSearch } from '../../../workout-sales/interfaces/custom-reports.interface';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import * as _ from 'lodash';
import { HrcyMock } from '../../mock/mock';

@Component({
  selector: 'app-lexus-dealer-at-glance',
  templateUrl: './lexus-dealer-at-glance.component.html',
  styleUrls: ['./lexus-dealer-at-glance.component.scss']
})
export class LexusDealerAtGlanceComponent implements OnInit {

  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  public displayColumsForTopTenDealerDealer: string[] = [];
  public lexusDealerAtGlanceReportDataSource = new MatTableDataSource([]);
  public currentTabIndex: number = 0;
  public lexusDealerAtGlancejsonObj;
  public isExpansionEnabled: boolean = false;
  public forgerockUrl: string = '';
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  public sources: Observable<any>[] = [];
  public selectedFilter = [];
  reportId: any;
  isFilterSame: boolean;
  previousFilter: any;
  selectedHierachy: any;
  public obj = Object.values;
  isProfileTable: boolean = false;
  selectedDateFilter: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly filterService: CustomReportsFiltersService,
    private readonly customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    this.getLexusDealerAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.selectedFilter.length > 0) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilter);
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection()
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(tabIndex) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.selectedDateFilter = obj['monthYear'];
    const dateObj = getMonthAndYear(obj['monthYear']);
    const filterObj = obj['dealer'];
    const levelName = obj['hierarchy']?.name;
    const levelId = obj['hierarchy']?.id;
    let requestObj;
    const viewId = this.getViewId(tabIndex, levelName)
    if (levelId === HIERARCHY_TYPE.DEALER) {
      requestObj = this.createPayload(dateObj.searchMonth, dateObj.searchYear, levelId, filterObj.MEMBER_CODE, filterObj.REGION_CODE, (filterObj.REGION_CODE + filterObj.DISTRICT_CODE), viewId);
    } else if (levelId === HIERARCHY_TYPE.REGION) {
      requestObj = this.createPayload(dateObj.searchMonth, dateObj.searchYear, levelId, null, filterObj.REGION_CODE, null, viewId);
    } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
      requestObj = this.createPayload(dateObj.searchMonth, dateObj.searchYear, levelId, null, null, null, viewId);
    }

    if (tabIndex === 0 || tabIndex === 1 || tabIndex === 2 || (tabIndex === 3 && (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL))) {
      this.sources = [this.customReportsApiService.getLexusDAAGForLexus(requestObj).pipe(this.customReportsService.handleError()?.bind(this))];
    }
    else if (tabIndex === 3 && levelId === HIERARCHY_TYPE.DEALER) {
      requestObj = this.createPayload(dateObj.searchMonth, dateObj.searchYear, levelId, filterObj.MEMBER_CODE, filterObj.REGION_CODE, (filterObj.REGION_CODE + filterObj.DISTRICT_CODE), viewId);
      this.sources = [this.customReportsApiService.getLsaagScores(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
      ]
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
    return this.sources;

  }

  createPayload(searchMonth, searchYear, hierarchy, dealer_no, region_no, district_cd, viewId) {
    const requestObj = {
      month: searchMonth,
      year: searchYear,
      hierarchy: hierarchy,
      region_no: region_no,
      district_cd: district_cd,
      dealer_no: dealer_no,
      view_id: viewId
    }
    return requestObj;
  }


  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          this.spinnerService.displaySpinner();
          if (filteredValues?.length > 0) {
            this.selectedFilter = filteredValues;
            this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
            if (this.isFilterSame === false) {
              this.selectedTabIndex = [];
              this.selectedTabIndex.push(this.currentTabIndex);
            }
            if (this.lexusDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
              this.getLexusDealerAtGlanceReport(); //initialise to empty object
              this.assignTabsAccordingToHrchy();
            }
            this.mapResponse();
          }
        }, error: (error) => {
          console.warn('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  assignTabsAccordingToHrchy() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const levelId = obj['hierarchy']?.id;
    if (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL) {
      const filteredTabList = this.lexusDealerAtGlancejsonObj?.tabList.filter((_tab, i) => (i !== 4));
      this.lexusDealerAtGlancejsonObj.tabList = filteredTabList
    } else if (levelId === HIERARCHY_TYPE.DEALER) {
      const filteredTabList = this.lexusDealerAtGlancejsonObj?.tabList.filter((_tab, i) => i !== 0);
      this.lexusDealerAtGlancejsonObj.tabList = filteredTabList
    }
  }

  public async mapResponse() {
    let currentTableResponse;
    const obj = getObjFromFilteredArray(this.selectedFilter);
    let levelId = obj['hierarchy']?.id;
    await this.getTabDataSource(this.currentTabIndex);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          let tableData = resp[0]?.body[0]?.reportData;
          let columnHeaderName = (this.currentTabIndex === 3 && levelId === HIERARCHY_TYPE.DEALER) ? [] : this.obj(this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData, levelId));
          let alternateRowColorClass = 'dxfw-all-wht-odd-class';
          let setStylesForColumns = [{ columns: ['CATEGORY', 'ACC_NAME'], styles: { textAlign: 'left', paddingLeft: '2px' } }, { columns: 'default', styles: { textAlign: 'center' } }];
          const dataFormattingColumns = 'all';
          const removeParentsForNegativeVal = true;
          const printZeros = true;
          this.customReportsService.setPdfExcelIcons(true);
          if (this.currentTabIndex === 0) {

            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ ['Lexus Complete Maintenance Care (LCMC)']: 1, ' ': 1, ['Dealer 6-Month History']: 6, ['Month to Date (Average Dlr)']: 4 }];
              const datasourceName = 'LEXUSCompleteMaintainDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            }
            else if (levelId === HIERARCHY_TYPE.REGION) {
              const tableHeader = [{ ['PARTS']: 1, ' ': 1, ['MTD(Average Dealer)']: 2, ['Six Month Area Trend']: 6 }];
              const datasourceName = 'LEXUSPartsDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            }
            else if (levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ ['PARTS']: 1, ' ': 1, ['MTD(Average Dealer)']: 1, ['Six Month Area Trend']: 6 }];
              const datasourceName = 'LEXUSPartsDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            }
          }
          if (this.currentTabIndex === 1) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ ['Service Retention']: 1, ['Dealer Rolling 12-Month History By Month']: 6, ['Rolling 12-Month History By Month']: 3 }];
              const datasourceName = 'LEXUServiceRetentionDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            } else if (levelId === HIERARCHY_TYPE.REGION) {
              const tableHeader = [{ ['Lexus Complete Maintenance Care (LCMC)']: 1, ' ': 1, ['MTD(Average Dealer)']: 2, ['Six Month Area Trend']: 6 }];
              const datasourceName = 'LEXUSCompleteMaintainDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ ['Lexus Complete Maintenance Care (LCMC)']: 1, ' ': 1, ['MTD(Average Dealer)']: 1, ['Six Month Area Trend']: 6 }];
              const datasourceName = 'LEXUSCompleteMaintainDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            }
          }
          if (this.currentTabIndex === 2) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              const tableHeader = [{ ['Volume)']: 1, ' ': 1, ['Dealer 6-Month History']: 6, ['Year to Date']: 4 }];
              const datasourceName = 'LEXUSVolumeDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, removeParentsForNegativeVal, setStylesForColumns }];
            } else if (levelId === HIERARCHY_TYPE.REGION) {
              const tableHeader = [{ ['Service Retention']: 1, ['Rolling 12-Month History By Month']: 2, ['Dealer Rolling 12-Month History By Month']: 6 }];
              const datasourceName = 'LEXUServiceRetentionDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ ['Service Retention']: 1, ['Rolling 12-Month History By Month']: 1, ['Dealer Rolling 12-Month History By Month']: 6 }];
              const datasourceName = 'LEXUServiceRetentionDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, setStylesForColumns }];
            }
          }
          if (this.currentTabIndex === 3) {
            if (levelId === HIERARCHY_TYPE.DEALER) {
              if (resp.length > 0 && resp[0].status === 200) {
                const obj = [{
                  "datasourceName": "profileDS",
                  "tableData": resp[0]?.body,
                  "isRegionalTable": false,
                }];
                currentTableResponse = obj;
              }
            } else if (levelId === HIERARCHY_TYPE.REGION) {
              const tableHeader = [{ ['Volume)']: 1, ' ': 1, ['Year to Date']: 2, ['Dealer 6-Month History']: 6 }];
              const datasourceName = 'LEXUSVolumeDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, removeParentsForNegativeVal, setStylesForColumns }];

            } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
              const tableHeader = [{ ['Volume)']: 1, ' ': 1, ['Year to Date']: 1, ['Dealer 6-Month History']: 6 }];
              const datasourceName = 'LEXUSVolumeDS';

              currentTableResponse = [{ datasourceName, tableHeader, columnHeaderName, tableData, alternateRowColorClass, dataFormattingColumns, printZeros, removeParentsForNegativeVal, setStylesForColumns }];
            }
          }

        }

        if (currentTableResponse) {
          this.lexusDealerAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
        }
        this.previousFilter = _.cloneDeep(this.selectedFilter);
        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.warn('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  getDisplayedColumns(reportHeaderData: any, levelId?) {
    let columnHeaders: string[] = [];
    if (reportHeaderData.length > 0) {
      if ((this.currentTabIndex === 1 && levelId === HIERARCHY_TYPE.DEALER) || (this.currentTabIndex === 2 && (levelId === HIERARCHY_TYPE.REGION || levelId === HIERARCHY_TYPE.NATIONAL))) {
        columnHeaders = [`${reportHeaderData[0].HEADER}`];
      } else {
        columnHeaders = [`${reportHeaderData[0].HEADER}`, 'OBJ'];
      }

    }

    if (this.currentTabIndex === 0 || this.currentTabIndex === 1 || this.currentTabIndex === 2) {
      if (levelId === HIERARCHY_TYPE.REGION) {
        columnHeaders.push('AREA', "Nat'L");
      } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
        columnHeaders.push("Nat'L");
      }
    } else if (this.currentTabIndex === 3) {
      if (levelId === HIERARCHY_TYPE.REGION) {
        columnHeaders.push('Area YTD Avg.', "Nat'L YTD Avg.");
      } else if (levelId === HIERARCHY_TYPE.NATIONAL) {
        columnHeaders.push("Nat'L YTD Avg.");
      }
    }

    const dynamicHeaders: string[] = [];
    reportHeaderData.forEach((header: any) => {
      if (header.MONTH_NM) {
        dynamicHeaders.push(header.MONTH_NM);
      }

    });

    columnHeaders = columnHeaders.concat(dynamicHeaders.slice(0, 6));
    if (this.currentTabIndex === 0) {
      if (levelId === HIERARCHY_TYPE.DEALER) {
        columnHeaders.push('DLR', 'DIST', 'AREA', "Nat'L");
      }
    } else if (this.currentTabIndex === 1) {
      if (levelId === HIERARCHY_TYPE.DEALER) {
        columnHeaders.push('DIST', 'AREA', "Nat'L");
      }
    } else if (this.currentTabIndex === 2) {
      if (levelId === HIERARCHY_TYPE.DEALER) {
        columnHeaders.push('Dlr YTD', 'Dist. YTD Avg.', 'Area YTD Avg.', "Nat'L YTD Avg.");
      }
    }

    return columnHeaders;
  }



  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription?.unsubscribe();
    this.customReportsService.setPdfExcelIcons(false);

  }

  public getLexusDealerAtGlanceReport() {
    let tabList = [
      {
        tabName: 'Parts',
        datasource: []
      },
      {
        tabName: 'Lexus Complete Maintenance Care (LCMC)',
        datasource: []
      },
      {
        tabName: 'Service Retention',
        datasource: []
      },
      {
        tabName: 'Volume',
        datasource: []
      },
      {
        tabName: 'Dealer Profile',
        datasource: []
      }
    ];
    this.lexusDealerAtGlancejsonObj = {
      tabList: tabList
    }
  }

  public getViewId(tabIndex: number, level_type) {
    let viewIds = [];
    if (level_type === HrcyMock[0].name) {
      viewIds = ['lcmc_info', 'service_retention_info', 'volume_info', 'lsaag_dealer_profile'];
    } else if (level_type === HrcyMock[2].name || level_type === HrcyMock[3].name) {
      viewIds = ['parts_info', 'lcmc_info', 'service_retention_info', 'volume_info',];
    }
    return `${viewIds[tabIndex]}`;
  }

  public displayProfileSection() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    const levelId = obj['hierarchy']?.id;
    if (this.currentTabIndex === 3 && levelId === HIERARCHY_TYPE.DEALER) {
      this.isProfileTable = true;

    } else {
      this.isProfileTable = false;
    }
  }

  public hideHeaderColumns(reportHeaderColumns: any) {
    const reportHeaders = _.filter(reportHeaderColumns, x => !(x.includes(x.X_UNITS_FLG) && x.includes(x.X_ACCT_NO)));
    return reportHeaders;
  }

}