import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CustomReportsFiltersService } from './custom-report-filters.service';
import { COMPANY_TYPE, HIERARCHY_TYPE, reportId, reportUrlType } from './../../../constants/constants'
import { getImageFromBase64, getLogosForDealers, getLogosForReports } from '../utils/common-utils';

interface PdfData {
    monthYear?: string,
    reportTitle?: string,
    memberName?: string,
    companyId?: number,
    reportId?: number,
    headerData?: any;
    headerColor?: string,
    selectedHierachy?: string
}

const textColor: any = [
    [51, 51, 51],     // index 0 
    [154, 144, 100],  // index 1
    [70, 160, 216],   // index 2 
    [255, 255, 255],  // index 3 (white) 
    [235, 231, 178],  // index 4 
    [255, 0, 0],      // index 5
    [199, 193, 169],  // index 6 
    [212, 212, 212]   // index 7 (gray)
]

const textFontSize = {
    x3small: 6,
    xxsmall: 7,
    xsmall: 8,
    small: 10,
    medium: 12,
    large: 14,
    xlarge: 16
}

const cellHeight = {
    x5small: 4,
    x4small: 5,
    x3small: 6,
    xxsmall: 7,
    xsmall: 8,
    small: 10,
}

const cellWidth = {
    auto: "auto",
    wrap: "wrap",
    x5small: 8,
    x4small: 10,
    x3small: 13,
    xxsmall: 20,
    xsmall: 25,
    small: 30,
    medium: 35,
    lmedirm: 40,
    large: 50,
    xlarge: 60,
    xxlarge: 70
}

const textPosition = {
    center: 'center',
    left: 'left',
    right: 'right',
}

interface PdfStyle {
    headerColor?: string,
    headerFontSize?: number,
    headerFontColor?: string,
    bodyFontSize?: number,
    bodyFontColor?: string,
    firstColumnTextColor?: any,
    secondColumnTextColor?: any,
    oddRowsColor?: any,
    colorForLastRow?: any,
    textColorForNegativeValues?: any,
    minCellHeight?: number,
    cellPadding?: number,
    columnStyles?: {
        zeroth?: any,
        first?: any,
        second?: any,
        third?: any,
        fourth?: any,
        fifth?: any,
        sixth?: any,
        seventh?: any,
        eighth?: any,
        nineth?: any,
        tenth?: any,
        eleventh?: any,
        twelth?: any,
        thirteen?: any,
        foruteen?: any,
        fifteen?: any,
        sixteen?: any,
        seventeen?: any,
        eighteen?: any,
        nineteen?: any,
        twenty?: any,
        twentyone?: any
    }
}

@Injectable({
    providedIn: 'root'
})
export class CustomReportsExportPdfService {

    private readonly exportToPdf = new BehaviorSubject<any>('');
    private pdfData: PdfData;
    finalY: number;
    tablesGapCount: number = 2;
    cellBgColors: any = [];
    textColorforColumn:any =[];

    constructor(private spinnerService: SpinnerService, private customReportFilterService: CustomReportsFiltersService) {
    }

    public setExportToPdf(exportPdf: boolean): void {
        this.exportToPdf.next(exportPdf);
    }

    public getExportToPdf(): Observable<boolean> {
        return this.exportToPdf.asObservable();
    }

    setDataToPdf(reportTile: string, reportId: number) {
        this.pdfData = {
            monthYear: '',
            reportTitle: '',
            reportId: null,
            memberName: '',
            companyId: null
        }

        // fetch dataset to filter the table
        this.customReportFilterService.getSelectedValues().subscribe(data => {
            data.forEach((element: any) => {
                if (element.key === "dealer") {
                    this.pdfData.memberName = `${element.value.MEMBER_CODE} - ${element.value.MEMBER_NAME}`
                }
                if (element.key === "monthYear") {
                    this.pdfData.monthYear = `DATE: ${element.value.toUpperCase()}`
                }
                if (element.key === "hierarchy") {
                    this.pdfData.reportTitle = `${element?.value.name.toUpperCase()==="DEALER"? ' ': `${element?.value.name.toUpperCase()} - ` } ${reportTile ? reportTile : ''}`
                    this.pdfData.selectedHierachy = element?.value.name;
                }
                if (element.key === "company") {
                    this.pdfData.companyId = element.value.id
                }
            });
        })
        if(this.pdfData.reportId === 91 || this.pdfData.reportId === 95 ){
            this.pdfData.memberName = 'TOYOTA FINANCIAL SERVICES'
        }
        this.pdfData.reportTitle = reportTile;
        this.pdfData.reportId = reportId;

    }

    addToyotaAndLexusLogos(pdf) {
        if (this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY || this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED || this.pdfData.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT || this.pdfData.reportId === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT) {
            let imgData = getImageFromBase64(this.pdfData.companyId);
            pdf.addImage(imgData, 'JPEG', 10, 12, 55, 20); // Adjust the dimensions and position as needed
            this.finalY = 36;
        }
    }

    addToyotaAndLexusLogosForDealer(pdf) {
        if (this.pdfData.selectedHierachy === 'Dealer' && (this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY || this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED)) {
            let imgData2 = getLogosForDealers(this.pdfData.companyId)
            pdf.addImage(imgData2, 'JPEG', 225, 12, 55, 20); // Adjust the dimensions and position as needed
            this.finalY = 36;
        }
    }

    addLogosForReports(pdf) {
        if (this.pdfData.reportId === reportId.LEXUS_PO_SNAPSHOT) {
            const imgData2 = getLogosForReports(reportId.LEXUS_PO_SNAPSHOT)
            pdf.addImage(imgData2, 'JPEG', 10, 12, 55, 20); // Adjust the dimensions and position as needed
            this.finalY = 36;
        }
    }

    async exportPdf() {
        let pdf = new jsPDF('l', 'mm', 'a4');   // create a new PDF for A4
        this.finalY = 16;    // next report will start from this Y index
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(11);
        pdf.text(this.pdfData.reportTitle, 10, 10);
        pdf.text(this.pdfData.memberName, 68, 32);
        pdf.text(this.pdfData.monthYear, 243, 10);

        this.addToyotaAndLexusLogos(pdf);
        this.addToyotaAndLexusLogosForDealer(pdf);
        this.addLogosForReports(pdf);

        this.spinnerService.displaySpinner();
        const tabLabels = Array.from(document.querySelectorAll('.mat-mdc-tab')); // get all tab labels
        const originalActiveTab = document.querySelector('.mdc-tab--active');
        const originTab = originalActiveTab as HTMLElement;
        if (tabLabels && tabLabels.length > 0) {  // check pdf has tabs
            for (let i = 0; i < tabLabels.length; i++) {

                // programmatically click on the tab
                const tab = tabLabels[i] as HTMLElement;
                tab.click();

                // wait for Angular to update the view and for the tab content to be rendered
                await new Promise(resolve => setTimeout(resolve, 8000));

                const tables = document.querySelectorAll('.mat-mdc-tab-body-active table'); // select all tables in the active tab
                this.printTables(tables, pdf, this.finalY);

            }
            this.savePdf(pdf);
            originTab.click();   // back to the first tab
        } else {
            const tables = document.querySelectorAll('table'); // select tables
            this.printTables(tables, pdf, this.finalY)
            this.savePdf(pdf);
        }
    }

    savePdf(pdf: any) {
        pdf.save(`${this.pdfData.reportTitle}.pdf`);
        const pdfBlob = pdf.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        window.open(url, '_blank');
        this.spinnerService.hideSpinner();
    }

    setPdfStyles(pdfStyles: PdfStyle) {
        const textColorForCol = "rgb(255, 0, 0)";
        pdfStyles = {
            headerFontSize: textFontSize.small,
            bodyFontSize: textFontSize.small,
            firstColumnTextColor: textColor[0],
            oddRowsColor: textColor[7],
            colorForLastRow: textColor[3],
            textColorForNegativeValues: textColor[5],
            minCellHeight: cellHeight.small,
            cellPadding: 1,
            columnStyles: {
                zeroth: { textPosition: textPosition.left, cellWidth: "auto", fontStyle: 'normal' },
                first: { textPosition: textPosition.center, cellWidth: "auto", fontStyle: 'normal' },
                second: { textPosition: textPosition.center, cellWidth: "auto" },
                third: { textPosition: textPosition.center, cellWidth: "auto" },
                fourth: { textPosition: textPosition.center, cellWidth: "auto" },
                fifth: { textPosition: textPosition.center, cellWidth: "auto" },
                sixth: { textPosition: textPosition.center, cellWidth: "auto" },
                seventh: { textPosition: textPosition.center, cellWidth: "auto" },
                eighth: { textPosition: textPosition.center, cellWidth: "auto" },
                nineth: { textPosition: textPosition.center, cellWidth: "auto" },
                tenth: { textPosition: textPosition.center, cellWidth: "auto" },
                eleventh: { textPosition: textPosition.center, cellWidth: "auto" },
                twelth: { textPosition: textPosition.center, cellWidth: "auto" },
                thirteen: { textPosition: textPosition.center, cellWidth: "auto" },
                foruteen: { textPosition: textPosition.center, cellWidth: "auto" },
                fifteen: { textPosition: textPosition.center, cellWidth: "auto" },
                sixteen: { textPosition: textPosition.center, cellWidth: "auto" },
                seventeen: { textPosition: textPosition.center, cellWidth: "auto" },
                eighteen: { textPosition: textPosition.center, cellWidth: "auto" },
                nineteen: { textPosition: textPosition.center, cellWidth: "auto" },
                twenty: { textPosition: textPosition.center, cellWidth: "auto" },
                twentyone: { textPosition: textPosition.center, cellWidth: "auto" }
            }
        }
        // ReportTitle.Service_Dept_Expense_And_Sales_Analysis
        if (this.pdfData.reportId === reportUrlType['service-dept-analysis']) {
            pdfStyles.headerFontSize = textFontSize.xxsmall,
                pdfStyles.bodyFontSize = textFontSize.xxsmall,
                pdfStyles.firstColumnTextColor = textColor[2],
                pdfStyles.oddRowsColor = textColor[3],
                pdfStyles.colorForLastRow = textColor[4],
                pdfStyles.textColorForNegativeValues = textColor[5],
                pdfStyles.minCellHeight = cellHeight.x5small,
                pdfStyles.cellPadding = 1,
                pdfStyles.columnStyles = {
                    zeroth: { textPosition: textPosition.center, cellWidth: cellWidth.x5small, fontStyle: 'normal' },
                    first: { textPosition: textPosition.left, cellWidth: cellWidth.medium, fontStyle: 'bold' },
                    second: { textPosition: textPosition.right, cellWidth: cellWidth.x3small }, // YTD
                    third: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    fourth: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    fifth: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    sixth: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    seventh: { textPosition: textPosition.right, cellWidth: cellWidth.x3small }, //First
                    eighth: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    nineth: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    tenth: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    eleventh: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    twelth: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    thirteen: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    foruteen: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    fifteen: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    sixteen: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    seventeen: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    eighteen: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    nineteen: { textPosition: textPosition.right, cellWidth: cellWidth.x3small },
                    twenty: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },
                    twentyone: { textPosition: textPosition.right, cellWidth: cellWidth.x4small },

                }
        } else if (this.pdfData.reportId === reportUrlType['part-department-analysis']) {
            pdfStyles.headerFontSize = textFontSize.small;
            pdfStyles.bodyFontSize = textFontSize.small;

        } else if (this.pdfData.reportId === reportUrlType['dealer-review']) {
            this.pdfData.headerData = ['SALES', 'SERVICES', 'Toyota Dealer Agreement', 'Image USA'];
            this.pdfData.headerColor = '#C1B7CA';
            pdfStyles.headerFontSize = textFontSize.xsmall;
            pdfStyles.bodyFontSize = textFontSize.xsmall;
            pdfStyles.oddRowsColor = '#f0d9d2';
            this.tablesGapCount = 0;
        } else if (this.pdfData.reportId === reportUrlType['tfs-national-dpr'] || this.pdfData.reportId === reportUrlType['national-dpr']) {
            this.pdfData.headerData = ['NEW', 'COMBINED', 'USED'];
            this.pdfData.headerColor = '#fffae5';
            this.tablesGapCount = 0;
        } else if (this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED) {
            if (this.pdfData.companyId === COMPANY_TYPE.TOYOTA) {
                this.pdfData.headerData = ['NEW', 'COMBINED', 'CERTIFIED USED', 'NON-CERTIFIED USED', 'COMBINED'];
            } else {
                this.pdfData.headerData = ['NEW', 'COMBINED', 'CERTIFIED USED', 'COMBINED'];
            }
            this.pdfData.headerColor = '#fffae5';
            this.textColorforColumn = [textColorForCol]
            this.tablesGapCount = 0;
        } else if (this.pdfData.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY) {
            this.cellBgColors = ["rgb(0, 0, 0)"];
        } else if (this.pdfData.reportId === reportUrlType['tcuv-performance-card']) {
            this.tablesGapCount = 0;
            this.cellBgColors = ["rgb(0, 128, 0)", textColorForCol];
            this.pdfData.headerData = ['OPERATIONS', 'USEDVEHICLEINVENTORY'];
            this.pdfData.headerColor = '#fffae5';
        } else if (this.pdfData.reportId === reportUrlType['sales-analysis-new-vehicle']) {
            this.tablesGapCount = 0;
        } else if (this.pdfData.reportId === reportUrlType['sales-analysis-used-vehicle']) {
            this.tablesGapCount = 0;
        } else if (this.pdfData.reportId === reportUrlType['lexus-sales-at-glance']) {
            this.cellBgColors = ["rgb(255, 153, 204)", "rgb(204, 255, 204)"];
            this.textColorforColumn = [textColorForCol];
        }

        return pdfStyles;
    }

    printTables(tables: any, pdf: any, finalY: number) {
        let pdfStyles: PdfStyle;
        pdfStyles = this.setPdfStyles(pdfStyles);
        if (tables && tables.length > 0) {
            for (let j = 0; j < tables.length; j++) {
                finalY = this.createTable(pdf, j, finalY, pdfStyles);
            }
        }
        this.finalY = finalY;
    }

    createTable(pdf: any, tableIndex: number, finalY: number, pdfStyles: PdfStyle) {
        autoTable(pdf, {
            html: `#pdfTable-${tableIndex}`,
            styles: {
                halign: 'center', textColor: textColor[0],
                lineColor: textColor[1], lineWidth: 0.10,

            },
            headStyles: {
                fillColor: textColor[6], lineColor: textColor[1],
                lineWidth: 0.10, minCellHeight: pdfStyles.minCellHeight, fontSize: pdfStyles.headerFontSize,
                cellPadding: pdfStyles.cellPadding
            },
            bodyStyles: {
                halign: 'right',
                minCellHeight: pdfStyles.minCellHeight, // Increase row height
                fontSize: pdfStyles.bodyFontSize,
                cellPadding: pdfStyles.cellPadding
            },
            columnStyles: {
                0: {
                    halign: pdfStyles.columnStyles.zeroth.textPosition, cellWidth: pdfStyles.columnStyles.zeroth.cellWidth,
                },
                1: {
                    halign: pdfStyles.columnStyles.first.textPosition, cellWidth: pdfStyles.columnStyles.first.cellWidth,
                    textColor: pdfStyles.firstColumnTextColor,
                    fontStyle: pdfStyles.columnStyles.first.fontStyle

                },
                2: {
                    halign: pdfStyles.columnStyles.second.textPosition, cellWidth: pdfStyles.columnStyles.second.cellWidth,
                },
                3: {
                    halign: pdfStyles.columnStyles.third.textPosition, cellWidth: pdfStyles.columnStyles.third.cellWidth,
                },
                4: {
                    halign: pdfStyles.columnStyles.fourth.textPosition, cellWidth: pdfStyles.columnStyles.fourth.cellWidth,
                },
                5: {
                    halign: pdfStyles.columnStyles.fifth.textPosition, cellWidth: pdfStyles.columnStyles.fifth.cellWidth,
                },
                6: {
                    halign: pdfStyles.columnStyles.sixth.textPosition, cellWidth: pdfStyles.columnStyles.sixth.cellWidth,
                },
                7: {
                    halign: pdfStyles.columnStyles.seventh.textPosition, cellWidth: pdfStyles.columnStyles.seventh.cellWidth,
                },
                8: {
                    halign: pdfStyles.columnStyles.eighth.textPosition, cellWidth: pdfStyles.columnStyles.eighth.cellWidth,
                },
                9: {
                    halign: pdfStyles.columnStyles.nineth.textPosition, cellWidth: pdfStyles.columnStyles.nineth.cellWidth,
                },
                10: {
                    halign: pdfStyles.columnStyles.tenth.textPosition, cellWidth: pdfStyles.columnStyles.tenth.cellWidth,
                },
                11: {
                    halign: pdfStyles.columnStyles.eleventh.textPosition, cellWidth: pdfStyles.columnStyles.eleventh.cellWidth,
                },
                12: {
                    halign: pdfStyles.columnStyles.twelth.textPosition, cellWidth: pdfStyles.columnStyles.twelth.cellWidth,
                },
                13: {
                    halign: pdfStyles.columnStyles.thirteen.textPosition, cellWidth: pdfStyles.columnStyles.thirteen.cellWidth,
                },
                14: {
                    halign: pdfStyles.columnStyles.foruteen.textPosition, cellWidth: pdfStyles.columnStyles.foruteen.cellWidth,
                },
                15: {
                    halign: pdfStyles.columnStyles.fifteen.textPosition, cellWidth: pdfStyles.columnStyles.fifteen.cellWidth,
                },
                16: {
                    halign: pdfStyles.columnStyles.sixteen.textPosition, cellWidth: pdfStyles.columnStyles.sixteen.cellWidth,
                },
                17: {
                    halign: pdfStyles.columnStyles.seventeen.textPosition, cellWidth: pdfStyles.columnStyles.seventeen.cellWidth,
                },
                18: {
                    halign: pdfStyles.columnStyles.eighteen.textPosition, cellWidth: pdfStyles.columnStyles.eighteen.cellWidth,
                },
                19: {
                    halign: pdfStyles.columnStyles.nineteen.textPosition, cellWidth: pdfStyles.columnStyles.nineteen.cellWidth,
                },
                20: {
                    halign: pdfStyles.columnStyles.twenty.textPosition, cellWidth: pdfStyles.columnStyles.twenty.cellWidth,
                },
            },
            tableLineColor: textColor[1],
            tableLineWidth: 0.10,
            startY: finalY,
            margin: {
                left: 10,
            },
            theme: 'grid',
            showHead: 'firstPage',
            rowPageBreak: 'avoid',
            didParseCell: (data) => {
                if (data.section === 'head') {
                    data.cell.styles.valign = 'middle';
                }
                if (this.pdfData.headerData?.includes(data.cell.text[0])) {
                    data.cell.styles.fillColor = this.pdfData.headerColor;
                }
                if (data.section === 'body') {
                    const rowIndex = data.row.index;
                    data.cell.styles.valign = 'middle';
                    var rows = data.table.body;
                    if (rowIndex % 2 === 0) {  // set color for odd rows
                        data.cell.styles.fillColor = pdfStyles.oddRowsColor;
                    }
                    if (rowIndex === rows.length - 1) {
                        data.cell.styles.fillColor = pdfStyles.colorForLastRow; // set color for the last row
                    }
                    const cellValue = parseInt(data.cell.text[0], 10);
                    if (cellValue < 0) {
                        data.cell.styles.textColor = pdfStyles.textColorForNegativeValues; // Red color for negative values
                    }
                }
                if (data.cell.raw instanceof HTMLElement && this.cellBgColors?.length > 0) {
                    const tdElement = data.cell.raw;
                    if (tdElement) {
                        const bgColor = window.getComputedStyle(tdElement).backgroundColor;
                        if (this.cellBgColors?.includes(bgColor)) {
                            data.cell.styles.fillColor = bgColor;
                        }
                    }
                }
                if (data.cell.raw instanceof HTMLElement && this.textColorforColumn?.length > 0) {
                    const tdElement = data.cell.raw;
                    if (tdElement) {    
                      const spanElement = tdElement.querySelector('span.dxfw-custom-report-table-cell-highlightcolor');
                       if(spanElement){
                        data.cell.styles.textColor = pdfStyles.textColorForNegativeValues;
                       }
                    }
                }
            },

            didDrawCell: (data) => {
                data.cell.styles.valign = 'middle';
                const rowElement = (data.row.raw as any)?._element as HTMLTableRowElement;
                const xStart = data.cell.x;
                const xEnd = xStart + data.cell.width;
                const y = data.cell.y + data.cell.height;
                if (rowElement.classList.contains("border-bottom-gray")) {
                    pdf.setDrawColor(169, 169, 169);
                    pdf.setLineWidth(1.2);
                    pdf.line(xStart, y, xEnd, y);

                } else if (rowElement.classList.contains("border-bottom-black")) {
                    pdf.setDrawColor(0);
                    pdf.setLineWidth(0.8);
                    pdf.line(xStart, y, xEnd, y);
                }


            }
        })
        return (pdf as any).lastAutoTable.finalY + this.tablesGapCount;
    }

}
