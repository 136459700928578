import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IGridSearch } from '../../interfaces/workout-reports.interface';
import { SearchFilterService } from '../../services/search-filter.service';
import { environment } from '../../../../../environments/environment';
import { IConfig } from 'src/app/interface/common.interface';

@Component({
  selector: 'app-report-grid',
  templateUrl: './report-grid.component.html',
  styleUrls: ['./report-grid.component.scss']
})
export class ReportGridComponent implements OnInit, OnDestroy {

  public openSideNav = false;
  public searchObject: Array<IGridSearch> = [];
  public subscription: Subscription = new Subscription();
  public currentURL: string ='';
  public isCustomReports : boolean = false;
  public headerName : string = '';
  public displayCustomDashboard: boolean = false;
  private currentEnv: IConfig = environment;
  public menuId: number = 0;
  public env: string = '';
  public displayRegisterUser: boolean = false;
  public hideDashboardReports: boolean = false;

  constructor(
    private filterService: SearchFilterService,
    private router: Router ) { 
     this.displayCustomDashboard = this.currentEnv?.enableCustomReport;
     this.subscription.add(this.filterService.getRemovedVal().subscribe((value: IGridSearch) => {
      if (value && value.key) {
        // not doing anything
      } else {
         this.searchObject = []
      }
    }));
    this.env = environment?.name;
    this.currentURL = this.router.url;
    if (this.currentURL.includes('dashboard')) {
      this.isCustomReports = true;
      this.headerName = "DASHBOARDS";
      this.menuId = 3;
    } else if (this.currentURL.includes('operational')) {
      this.isCustomReports = false;
      this.headerName = "Operational Reports";
      this.menuId = 1;
    } else if (this.currentURL.includes('historical')) {
      this.isCustomReports = false;
      this.headerName = "Historical Reports";
      this.menuId = 2;
    } else {
      this.isCustomReports = false;
      this.headerName = 'Workout Reports';
    }

    if (this.env !== '' && this.env === 'DEV' && (this.currentURL.includes('operational') || this.currentURL.includes('historical'))) {
      this.displayRegisterUser = true;
    }

    if( this.isCustomReports === true && this.displayCustomDashboard === false){
      this.hideDashboardReports = true
    }
  }

  ngOnInit(): void {
    if(!this.isCustomReports){
    this.filterService.getSelectedGridFilterValues().subscribe({
      next: (selectedFilter) => {
        this.searchObject = selectedFilter;  
      }, error: (error) => {
        console.warn('Error handler:', error)
      }
    })
  }
  }

  public updateSearchObj(event: IGridSearch): void {  
    if (this.searchObject.length > 0) {
      const index = this.searchObject.findIndex((x: IGridSearch) => x.key === event.key);
      if (index === -1) {
        this.searchObject.push(event);
      } else {
        this.searchObject[index].value = event.value;
      }
    } else {
      this.searchObject.push(event);
    } 
   this.filterService.setSelectedGridFilterValues(this.searchObject);
  }

  public removeFilter(index: number,searchObj: IGridSearch): void {
    this.searchObject.splice(index, 1);
    this.filterService.setRemovedValues(searchObj);
  }

  public removeAllFilters(): void {
    this.searchObject = [];
    this.filterService.setRemovedValues({});
  }

  ngOnDestroy(): void {
      this.subscription.unsubscribe();
  }

} 
