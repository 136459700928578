<div class="date-dropdown dd-dropdown-filter-expansion">
  <mat-accordion class="filter-accordion" multi>
<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header class='filter-expansion-panel'>
    <mat-panel-title>
      <span class="filter-title">Date *</span>
      <div class="clear-container">
        <div
          class="filter-clear"
          (click)="onClear();$event.stopPropagation()">
          Clear
        </div>
        <div class="tmi icon tmi-arrow-half-right"></div>
      </div>
      </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field appearance="outline" class="interval dd-mat-mdc-select-form-field">
    <mat-select  panelClass ="dd-mat-mdc-select" matNativeControl (selectionChange)="setDefultFilterValues('monthYear',$event.value)"
      [(value)]="defaultSelected" placeholder="Select">
      <mat-option *ngFor="let interval of dateFilterData" [value]="interval.rpt_year_month" >
        {{ interval.rpt_year_month }}
      </mat-option>
    </mat-select>
    <!-- <mat-hint>{{hintLabel}}</mat-hint> -->

  </mat-form-field>
  <mat-error *ngIf="requiredMsg && defaultSelected === ''">{{requiredMsg}}</mat-error>
  <mat-error *ngIf=" !requiredMsg && noDataMessage && dateFilterData.length === 0">{{noDataMessage}}</mat-error>

</mat-expansion-panel>
</mat-accordion>
</div>