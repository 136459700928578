import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SpinnerService } from '../../../../services/spinner.service';
import { WorkoutReportsApiService } from '../../services/workout-reports-api.service';
import { ReportListTableDef } from '../../config/grid.config';
import { environment } from '../../../../../environments/environment';
import { IGridParams, IGridSearch, IReportList } from '../../interfaces/workout-reports.interface';
import { Subscription } from 'rxjs';
import { SearchFilterService } from '../../services/search-filter.service';
import { Constants, reportId } from '../../../../constants/constants';
import { IActivityParams, IConfig } from '../../../../interface/common.interface';
import { EFeaturesActivityLog } from '../../../../constants/activity-log.enum';
import { FinanceDisclaimerModelComponent } from 'src/app/modules/custom-reports/reports/finance-disclaimer-model/finance-disclaimer-model.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-content',
  templateUrl: './report-content.component.html',
  styleUrls: ['./report-content.component.scss']
})
export class ReportContentComponent implements OnInit, OnDestroy {

  @ViewChild(MatSort) sort: MatSort | undefined;
  @Output() updateSearch: EventEmitter<IGridSearch> = new EventEmitter<IGridSearch>();
  @Input() isCustomReport: boolean;
  @Input() menuId: number;
  @Input() SelectedGridFilter: any;
  public reportDataSource = new MatTableDataSource<IReportList>([]);
  public tableDef = ReportListTableDef;
  public displayedColumns: string[] = [];
  public displayedSearches: string[] = [];
  public searchParams: IGridParams = {
    name: '',
    department: [''],
    dateLastUse: '',
    frequency: '',
    dataAsOf: ''
  };
  public forgerockUrl: string = '';
  public subscription: Subscription = new Subscription();
  public legacyUrl: string = '';
  public currentUrl: string = '';

  constructor(
    private readonly workoutReportsAPIService: WorkoutReportsApiService,
    private readonly spinnerService: SpinnerService,
    private router: Router,
    private readonly dialog: MatDialog,
    private filterService: SearchFilterService
  ) {
    this.subscription.add(this.filterService.getRemovedVal()?.subscribe((value: IGridSearch) => {
      if (value && value.key) {
        if (value.key === 'department') {
          this.searchParams[value.key] = [''];
        } else {
          this.searchParams[value.key] = '';
        }
        this.reportDataSource.filter = JSON.stringify(this.searchParams);
      } else {
        this.searchParams.name = '';
        this.searchParams.dateLastUse = '';
        this.searchParams.department = [''];
        this.searchParams.frequency = '';
        this.searchParams.dataAsOf = '';
        this.reportDataSource.filter = '';
      }
    }));
  }

  ngOnInit(): void {
    if (this.SelectedGridFilter && Object.keys(this.SelectedGridFilter).length > 0) {
      this.SelectedGridFilter.forEach(filter => {
        this.searchParams[filter.key] = filter.value;
      });
      this.reportDataSource.filter = JSON.stringify(this.searchParams);
    }
    this.forgerockUrl = environment?.forgerockUrl;
    this.spinnerService.displaySpinner();
    if (this.isCustomReport) {
      this.tableDef = this.tableDef.filter(def => def.isCustomReport === true)
    }
    this.displayedColumns = this.tableDef?.map(a => a.searchFieldName);
    this.updateSearches();
    this.getReportList();
  }

  public getReportList(): void {
    const currentEnv: IConfig = environment;
    this.workoutReportsAPIService.getReportList(currentEnv, this.menuId).subscribe((data: Array<IReportList>) => {
      if (data && data.length) {

        // To sort the reports by Name
        data.sort((a, b) => a.name > b.name ? 1 : -1)
        this.reportDataSource.data = data;

        // To set sorting
        setTimeout(() => {
          this.reportDataSource.sort = this.sort;
        });

        // Predefined filter condition for Search
        this.reportDataSource.filterPredicate = (data: IReportList, filter: string): boolean => {
          let searchString = JSON.parse(filter);
          return data.name?.toString()?.trim()?.toLowerCase().indexOf(searchString.name?.toLowerCase()) !== -1 &&
            searchString.department?.some((dept: string) => data.department?.toString()?.trim()?.toLowerCase().indexOf(dept.toLowerCase()) !== -1) &&
            data.frequency?.toString()?.trim()?.toLowerCase().indexOf(searchString.frequency?.toLowerCase()) !== -1;
        }
        this.getFilterConditions();
        this.spinnerService.hideSpinner();
      } else {
        this.spinnerService.hideSpinner();
      }
    }, err => {
      this.spinnerService.hideSpinner();
    })
  }

  public rowClicked(reportInfo: IReportList) {
    this.showDisclaimerForFinanceReport(reportInfo);
  }

  public navigateToReport(reportInfo: IReportList) {
    this.addActivityLog(reportInfo);
    // condition to check legacy or Workout reports
    if (reportInfo && reportInfo.islegacyreport) {
      // navigate to legacy app
      this.legacyUrl = reportInfo.url;
    } else {
      if (reportInfo && reportInfo.dashboardid) {
        const defaultTime = Constants.forgeRockSessionTime;
        const setUpTime: string = localStorage.getItem('forgerock-session');
        if (setUpTime !== null) {
          let currentTime = Date.now();
          if (Number(currentTime) - Number(setUpTime) > defaultTime) {
            localStorage.removeItem('forgerock-session');
            this.setReportSession(reportInfo);
          } else {
            reportInfo.isFirst = false;
            this.urlNavigation(reportInfo);
          }
        } else {
          this.setReportSession(reportInfo);
        }
      } else {
        this.urlNavigation(reportInfo);
      }
    }
  }

  public showDisclaimerForFinanceReport(reportInfo) {
    if (reportInfo.department === Constants.FinanceReports) {
      const dialogRef = this.dialog.open(FinanceDisclaimerModelComponent, {
        panelClass: ['dd-mat-mdc-dialog'],
        maxWidth: 470,
        closeOnNavigation: true,
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.event) {
          this.navigateToReport(reportInfo)
        }
      });
    } else {
      this.navigateToReport(reportInfo)
    }
  }

  public updateSearches(): void {
    if (!this.isCustomReport) {
      this.displayedSearches = this.tableDef?.map((a => {
        return a.searchFieldName ? 'search-' + a.searchFieldName : '';
      }))
    }
  }

  public updateSearchValue(searchKey: string): void {
    if (this.searchParams.department?.length === 0) {
      this.searchParams.department = [''];
    }
    this.reportDataSource.filter = JSON.stringify(this.searchParams);
    const searchChips = { key: searchKey, value: this.searchParams[searchKey] }
    this.updateSearch.emit(searchChips);
  }

  public removeAllFilters(): void {
    this.filterService.setRemovedValues({});
  }

  public urlNavigation(reportInfo: IReportList): void {
    if (reportInfo?.feature_id === reportId.PARTS_DEPARTMENT) {
      this.router.navigateByUrl('/workout/dashboard-reports/part-department-analysis', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.SERVICE_DEPARTMENT) {
      this.router.navigateByUrl('/workout/dashboard-reports/service-dept-analysis', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.SALES_ANALYSIS_USED_VEHICLE) {
      this.router.navigateByUrl('/workout/dashboard-reports/sales-analysis-used-vehicle', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.SALES_ANALYSIS_NEW_VEHICLE) {
      this.router.navigateByUrl('/workout/dashboard-reports/sales-analysis-new-vehicle', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.SERVICE_ANALYSIS) {
      this.router.navigateByUrl('/workout/dashboard-reports/service-analysis', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.PARTS_ANALYSIS) {
      this.router.navigateByUrl('/workout/dashboard-reports/parts-analysis', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.PS_FINANCIAL) {
      this.router.navigateByUrl('/workout/dashboard-reports/ps-financial', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT) {
      this.router.navigateByUrl('/workout/dashboard-reports/national-dpr', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.DSPM_MONTHLY_SUMMARY_REPORT) {
      this.router.navigateByUrl('/workout/dashboard-reports/dspm-monthly-summary-report', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.DSPM_YTD_SUMMARY) {
      this.router.navigateByUrl('/workout/dashboard-reports/dspm-ytd-summary', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.DEALER_REVIEW) {
      this.router.navigateByUrl('/workout/dashboard-reports/dealer-review', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT) {
      this.router.navigateByUrl('/workout/dashboard-reports/tfs-national-dpr', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.LEXUS_PO_SNAPSHOT) {
      this.router.navigateByUrl('/workout/dashboard-reports/lexus-po-snapshot', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.LEXUS_DEALER_GLANCE) {
      this.router.navigateByUrl('/workout/dashboard-reports/lexus-dealer-at-glance', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.LEXUS_SALES_GLANCE) {
      this.router.navigateByUrl('/workout/dashboard-reports/lexus-sales-at-glance', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.TCUV_DEALER_GLANCE) {
      this.router.navigateByUrl('/workout/dashboard-reports/tcuv-dealer-at-glance', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.TOYOTA_DEALER_GLANCE) {
      this.router.navigateByUrl('/workout/dashboard-reports/toyota-dealer-at-glance', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.TCUV_PERFORMANCE_CARD) {
      this.router.navigateByUrl('/workout/dashboard-reports/tcuv-performance-card', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY) {
      this.router.navigateByUrl('/workout/dashboard-reports/dealer-performance-fs', { state: { name: reportInfo.name } });
    } else if (reportInfo?.feature_id === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED) {
      this.router.navigateByUrl('/workout/dashboard-reports/dealer-performance-used-new-combined', { state: { name: reportInfo.name } });
    } else {
      this.workoutReportsAPIService.setReportInfo(reportInfo);
      this.router.navigateByUrl('workout/details');
    }
  }

  private setReportSession(selectedReport: IReportList): void {
    selectedReport.isFirst = true;
    this.urlNavigation(selectedReport);
  }


  private addActivityLog(reportInfo: IReportList): void {
    const activityLogParams: IActivityParams = {
      sessionId: window.sessionStorage.getItem('shell_window_id'),
      feature: reportInfo.department === EFeaturesActivityLog.DASHBOARD ? EFeaturesActivityLog.DASHBOARD : EFeaturesActivityLog.QS_REPORT,
      screenName: reportInfo.name,
      screenUrl: window.location.href,
      ipAddress: '',   // need to check if ip address exist in user session
      userAgent: this.getBrowserName(),
      screenParams: '',
    }
    const hierarchyTypeId = reportInfo.department.includes('TFS') ? 'tfs' : 'tmna';
    const reportList: any = JSON.parse(localStorage.getItem('report-list'))
    const reportListData = {
      [reportInfo?.feature_id]: { name: reportInfo.name, departmentType: hierarchyTypeId },
      ...reportList
    }
    localStorage.setItem('report-list', JSON.stringify(reportListData));

    this.workoutReportsAPIService.addActivityLog(activityLogParams).subscribe((data) => {
      if (data) {
        console.log('Activity Added successfully')
      }
    })
  }

  private getBrowserName(): string {
    let browserInfo = navigator.userAgent;
    let browser;
    if (browserInfo.includes('Opera') || browserInfo.includes('Opr')) {
      browser = 'Opera';
    } else if (browserInfo.includes('Edg')) {
      browser = 'Edge';
    } else if (browserInfo.includes('Chrome')) {
      browser = 'Chrome';
    } else if (browserInfo.includes('Safari')) {
      browser = 'Safari';
    } else if (browserInfo.includes('Firefox')) {
      browser = 'Firefox'
    } else {
      browser = 'unknown'
    }
    return browser;
  }

  private getFilterConditions(): void {
    const department = [];
    this.reportDataSource.data.forEach(reports => {
      if (department.length > 0) {
        if (department.findIndex(x => x.name === reports.department) === -1) {
          department.push({ name: reports.department, value: reports.department?.toLocaleLowerCase() })
        }
      } else {
        department.push({ name: reports.department, value: reports.department?.toLocaleLowerCase() })
      }
    });
    this.tableDef.forEach((columnInfo) => {
      if (columnInfo.searchFieldName === 'department') {
        columnInfo.searchFieldOptions = department;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
