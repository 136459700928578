import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { reportId, reportTitleOverridden, reportUrlType } from 'src/app/constants/constants';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';
import { SearchFilterService } from 'src/app/modules/workout-sales/services/search-filter.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HrcyMock } from '../../mock/mock';
import { CustomReportsExportPdfService } from '../../services/custom-report-export-pdf.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getImageFromBase64, getLogosForDealers } from '../../utils/common-utils';

const matHeaderCell = 'mat-header-cell';

@Component({
  selector: 'app-custom-report-dashboard',
  templateUrl: './custom-report-dashboard.component.html',
  styleUrls: ['./custom-report-dashboard.component.scss']
})
export class CustomReportDashboardComponent implements OnInit {
  public showSpinner: false;
  public searchObject: Array<CRGridSearch> = [];
  public subscription: Subscription = new Subscription();
  public currentSearchFilter: any = [];
  public multiSelectFilter = [];
  public reportId: number;
  public requiredFilters;
  public selectedIndex = 0;
  public isFirstTimeLoaded = true;
  selectedDateToFilter: string;
  backBtnTitle: string;
  clearFilterKey: string;
  selectedHierarchyName: string;
  hierarchyDropdownData = HrcyMock;
  dataLoaded: boolean[] = [false, false, false];
  removedKey: any;
  reportTitle = '';
  public isTabbedReport = true;
  public companyId;
  heirarchyFilterName: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly filterService: SearchFilterService,
    private readonly route: Router,
    public readonly customerReportFilterService: CustomReportsFiltersService,
    public readonly customReportExportPdfService: CustomReportsExportPdfService,
    public changeDetector: ChangeDetectorRef,
    private readonly customReportsService: CustomReportsService,
    private readonly customReportFilterService: CustomReportsFiltersService

  ) {
    this.subscription.add(this.filterService.getRemovedVal().subscribe((value: CRGridSearch) => {
      if (value && value.key) {
        this.removedKey = value;
      } else {
        this.removedKey = {};
        this.searchObject = [];
      }
    }));

    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];

    const reportList = JSON.parse(localStorage.getItem('report-list'));

    this.reportTitle = reportTitleOverridden?.[this.reportId] ?? reportList?.[this.reportId]?.name;

    if (this.isReportType()) {
      this.backBtnTitle = 'Back to Report List';
    } else {
      this.backBtnTitle = 'Back to Dashboards';
    }
  }

  ngOnInit(): void {
    this.getFilters();
    this.showExportPdfExcelButtons();
    this.customReportFilterService.getSelectedValues().subscribe(data => {
      data.forEach((element: any) => {
        if (element.key === "company") {
          this.companyId = element.value.id
        }
      });
    })
  }

  showButtons: boolean;

  showExportPdfExcelButtons() {
    this.customReportsService.getPdfExcelIcons().subscribe(value => this.showButtons = value)
  }

  public addSelectedDealers(event) {
    this.multiSelectFilter = event;
    if (this.multiSelectFilter.length <= this.selectedIndex) {
      this.selectedIndex = 0;
    }
  }

  public getFilters() {
    switch (this.reportId) {
      case reportId.LEXUS_PO_SNAPSHOT: {
        this.requiredFilters = {
          "dateFilter": true

        }
        break;
      }
      case reportId.TCUV_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true
        }
        break;
      }
      case reportId.LEXUS_SALES_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true
        }
        break;
      }
      case reportId.TOYOTA_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true
        }
        break;
      }
      case reportId.LEXUS_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true,
        }
        break;
      }
      case reportId.PARTS_DEPARTMENT: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true,
          "companyDropdownFilter": true

        }
        break;
      }
      case reportId.SERVICE_DEPARTMENT: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true,
          "companyDropdownFilter": true

        }
        break;
      }
      case reportId.SERVICE_ANALYSIS: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true   //hierarchyDropdownFilter
        }
        break;
      }
      case reportId.SALES_ANALYSIS_NEW_VEHICLE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.SALES_ANALYSIS_USED_VEHICLE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.PARTS_ANALYSIS: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.PS_FINANCIAL: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT: {
        this.requiredFilters = {
          "dateFilter": true,
          "companyDropdownFilter": true,
        }
        break;
      }
      case reportId.DEALER_REVIEW: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT: {
        this.requiredFilters = {
          "dateFilter": true,
          "companyDropdownFilter": true
        }
        break;
      }
      case reportId.TCUV_PERFORMANCE_CARD: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true
        }
        break;
      }
      case reportId.DSPM_MONTHLY_SUMMARY_REPORT: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true
        }
        break;
      }
      case reportId.DSPM_YTD_SUMMARY: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true
        }
        break;
      }
      case reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
    }
  }

  public addFilterChips(event: CRGridSearch): void {
    this.removedKey = {};
    this.filterService.setSelectedValues([event]);
    if (event?.value) {
      if (this.searchObject.length > 0) {
        const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === event.key);
        if (index === -1) {
          this.searchObject.push(event);
        } else {
          this.searchObject[index] = event;
        }
      } else {
        this.searchObject.push(event);
      }
      if (event.key === "hierarchy" && this.isReportType()) {
        this.getSelectedHierarchyName();
      }
    }
    else {
      const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === event?.key);
      this.removeFilter(index, event);
    }

    this.currentSearchFilter = this.searchObject;
    this.changeDetector.detectChanges();
    // save date to show in generated pdf
    this.customReportExportPdfService.setDataToPdf(this.reportTitle, this.reportId)
  }

  clearFilterChip(key) {
    const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === key);
    this.clearFilterKey = key;
    let removedValue = this.searchObject.splice(index, 1);
    if (key === 'company') {
      this.multiSelectFilter = [];
      const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'dealer');
      removedValue = this.searchObject.splice(index, 1);
      const index1 = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'dateFilter');
      removedValue = this.searchObject.splice(index1, 1);
      const index2 = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'hierarchy');
      removedValue = this.searchObject.splice(index2, 1);
    } else {
      this.multiSelectFilter = [];
    }
    // this.filterService.setRemovedValues(removedValue[0]);
  }

  public removeFilter(index: number, searchObj: CRGridSearch): void {
    this.searchObject.splice(index, 1);
    this.filterService.setRemovedValues(searchObj);
  }

  public removeAllFilters(): void {
    this.searchObject = [];
    this.filterService.setRemovedValues({});
  }

  public goBack(): void {
    if (this.isReportType()) {
      this.route.navigateByUrl('/workout/operational');
    } else {
      this.route.navigate(['/workout/dashboard']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public arrowClick(val) {
    if (val === true) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex -= 1;
    }
    const filter = { key: 'dealer', value: this.multiSelectFilter[this.selectedIndex].value };
    const key1 = 'HIERARCHY_ID';
    filter[key1] = this.multiSelectFilter[this.selectedIndex].value.HIERARCHY_ID;
    const key2 = 'REGION_CODE';
    filter[key2] = this.multiSelectFilter[this.selectedIndex].value.REGION_CODE;
    const key3 = 'DISTRICT_CODE';
    filter[key3] = this.multiSelectFilter[this.selectedIndex].value.DISTRICT_CODE;
    const key4 = 'MEMBER_NAME';
    filter[key4] = this.multiSelectFilter[this.selectedIndex].value.MEMBER_NAME;
    this.addFilterChips(filter);
    this.setFilterValues();
  }

  public moveToIndex(val) {
    this.selectedIndex = val;
    const filter = { key: 'dealer', value: this.multiSelectFilter[this.selectedIndex].value };
    const key1 = 'HIERARCHY_ID';
    filter[key1] = this.multiSelectFilter[this.selectedIndex].value.HIERARCHY_ID;
    const key2 = 'REGION_CODE';
    filter[key2] = this.multiSelectFilter[this.selectedIndex].value.REGION_CODE;
    const key3 = 'DISTRICT_CODE';
    filter[key3] = this.multiSelectFilter[this.selectedIndex].value.DISTRICT_CODE;
    const key4 = 'MEMBER_NAME';
    filter[key4] = this.multiSelectFilter[this.selectedIndex].value.MEMBER_NAME;
    this.addFilterChips(filter);
    this.setFilterValues();
  }

  public setFilterValues() {
    this.isFirstTimeLoaded = false;
    this.customerReportFilterService.setSelectedValues(this.searchObject);
    if (this.isReportType()) {
      // this.getSelectedHierarchyName()
    }
  }
  onSearchButtonClick() {
    this.setFilteredValuesBasedOnRequiredFilters();
  }

  setFilteredValuesBasedOnRequiredFilters() {
    if (Object.keys(this.requiredFilters).length === this.searchObject?.length) {
      this.setFilterValues();
    }
  }

  isReportType() {
    return (this.reportId === reportId.PARTS_DEPARTMENT || this.reportId === reportId.SERVICE_DEPARTMENT || this.reportId === reportId.SALES_ANALYSIS_NEW_VEHICLE || this.reportId === reportId.SALES_ANALYSIS_USED_VEHICLE || this.reportId === reportId.PARTS_ANALYSIS || this.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED || this.reportId === reportId.SERVICE_ANALYSIS || this.reportId === reportId.PS_FINANCIAL || this.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT || this.reportId === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT || this.reportId === reportId.DEALER_REVIEW || this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY
      || this.reportId === reportId.DSPM_MONTHLY_SUMMARY_REPORT || this.reportId === reportId.DSPM_YTD_SUMMARY
    );
  }

  getSelectedHierarchyName() {
    const heirarchyFilter: any = this.searchObject.find(val => val.key === "hierarchy");
    const heirarchyFilterName = heirarchyFilter ? this.hierarchyDropdownData.find(val => val.id === heirarchyFilter?.value?.id).name : "";
    this.heirarchyFilterName = heirarchyFilterName;
    if (heirarchyFilter) {
      const trimmedReportTitle = this.reportTitle.trim().toLowerCase();
      if (heirarchyFilterName === 'Dealer' && trimmedReportTitle.startsWith('dealer')) {
        this.selectedHierarchyName = '';
      } else {
        this.selectedHierarchyName = heirarchyFilterName + ' -';
      }
    }
  }

  async getTabbed() {
    const tabLabels = document.querySelectorAll('.mat-mdc-tab');
    return (tabLabels && tabLabels.length > 0);
  }

  async exportAllTables() {
    let tablesHtml = '';
    const originalIndex = this.selectedIndex;
    const matTabLebelActive = '.mdc-tab--active';
    this.spinnerService.displaySpinner();
    const tabLabels = Array.from(document.querySelectorAll('.mat-mdc-tab')); // get all tab labels
    const originalActiveTab = document.querySelector(matTabLebelActive);
    const originTab = originalActiveTab as HTMLElement;

    if (tabLabels && tabLabels.length > 0) {
      for (let i = 0; i < tabLabels.length; i++) {
        const tab = tabLabels[i] as HTMLElement;
        tab.click();
        originalActiveTab?.classList.remove(matTabLebelActive);
        tab.classList.add(matTabLebelActive);
        const tabText = tab.querySelector('.mat-tab-label-content');
        await new Promise(resolve => setTimeout(resolve, 8000));
        const tables = document.querySelectorAll('table');
        tablesHtml += Array.from(tables).map(table => table.outerHTML).join('');
        tab.classList.remove(matTabLebelActive);
      }
      originalActiveTab?.classList.add(matTabLebelActive);
      originTab.click();
    }
    else {
      tablesHtml += Array.from(document.querySelectorAll('table')).map(table => table.outerHTML).join('');
    }
    if (originalIndex > 0) {
      this.moveToIndex(originalIndex)
    }
    this.spinnerService.hideSpinner();

    const styleSheets = Array.from(document.styleSheets)
      .map(styleSheet => {
        try {
          return Array.from(styleSheet.cssRules)
            .map(rule => rule.cssText)
            .join(' ');
        } catch (e) {
          return '';
        }
      })
      .join(' ');


    return `
    <html>
      <head>
        <style>
          ${styleSheets}
          /* Ensure the body is scrollable */
          body {
            height: 100vh;
            overflow-y: auto;
            margin: 0;
            padding: 0;
          }
          table {
            width: 100%;
            margin-bottom: 20px;
          }
        </style>
      </head>
      <body>
        ${tablesHtml}
      </body>
    </html>
  `;
  }


  async exportExcelJS(): Promise<void> {
    this.spinnerService.displaySpinner();
    const parser = new DOMParser();
    this.isTabbedReport = await this.getTabbed();
    const finalHtml = await this.exportAllTables();
    const doc = parser.parseFromString(finalHtml, 'text/html');
    const tables = doc.querySelectorAll('table');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('All Tables');

    const columnWidths: number[] = [];
    let currentRow = 1;

    const title = this.reportTitle;
    if (this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY || this.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED || this.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT || this.reportId === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT) {
      const imgData = getImageFromBase64(this.companyId);
      const imageId = workbook.addImage({
        base64: imgData,
        extension: 'png',
      });
      worksheet.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 200, height: 100 },
      });
      const row = worksheet.getRow(5)
      row.commit();
      currentRow = 6;
    }

    if ((this.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED ||
      this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY) && this.heirarchyFilterName === 'Dealer') {
      const imgData2 = getLogosForDealers(this.companyId); // Assuming you have another image source
      const imageId2 = workbook.addImage({
        base64: imgData2,
        extension: 'png',
      });
      worksheet.addImage(imageId2, {
        tl: { col: 10, row: 0 },
        ext: { width: 200, height: 100 },
      });
    }

    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', size: 9, bold: true };
    worksheet.mergeCells('A1:C1');
    titleRow.alignment = { vertical: 'middle', horizontal: 'left' };

    let keyValueColumn = 4;

    if (this.multiSelectFilter.length > 0 && !this.isTabbedReport) {
      for (const filter of this.multiSelectFilter) {
        await this.createExcelKeyVals(worksheet, keyValueColumn, currentRow, filter.value);
        currentRow++;
      }
    } else {
      await this.createExcelKeyVals(worksheet, keyValueColumn, currentRow);
    }

    currentRow += 3;
    this.addStylesToTable(tables, worksheet, columnWidths, currentRow);

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${this.reportTitle}.xlsx`);
    });

    this.spinnerService.hideSpinner();
  }

  addStylesToTable(tables, worksheet, columnWidths, currentRow) {
    tables.forEach((table) => {
      const rows = table.querySelectorAll('tr');
      const isYtdMtdTable = table.classList.contains('dxfw-ytd-mtd-cell-class');
      const isAltWhtGryTable = table.classList.contains('dxfw-alt-wht-gry-class');
      const isProfileGreyEven = table.classList.contains('dxfw-alt-wht-gry-even-class');

      rows.forEach((row, rowIndex) => {
        const isGrayBorderElement = row.classList.contains('border-bottom-gray');
        const isBlackBorderElement = row.classList.contains('border-bottom-black');
        const isRowBgColor = row.classList.contains('row-green-bgcolor');
        const isRowBgColorOne = row.classList.contains('row-pink-bgcolor');
        const cells = row.querySelectorAll('td, th');
        const excelRow = worksheet.getRow(currentRow);
        let cellIndex = 1;
        const isLastChild = row.matches(':last-child');
        cells.forEach((cell) => {
          const colspan = cell.getAttribute('colspan');
          const bgColor = (cell as HTMLElement).style.backgroundColor;
          const excelCell = excelRow.getCell(cellIndex);
          excelCell.value = cell.textContent || '';
          excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
          excelCell.border = {
            top: { style: 'medium', color: { argb: 'E5EBEB' } },
            left: { style: 'medium', color: { argb: 'E5EBEB' } },
            bottom: { style: 'medium', color: { argb: 'E5EBEB' } },
            right: { style: 'medium', color: { argb: 'E5EBEB' } }
          };
          excelCell.font = { size: 8 }

          const textLength = excelCell.value ? excelCell.value.toString().length : 10;
          columnWidths[cellIndex - 1] = Math.max(columnWidths[cellIndex - 1] || 0, textLength);

          if (rowIndex % 2 === 0) {
            this.setYtdTable(isYtdMtdTable, excelCell, isAltWhtGryTable, isProfileGreyEven);
          }

          this.IsYtdTableLastChild(isYtdMtdTable, isLastChild, excelCell);

          if (cell.classList.contains(matHeaderCell)) {
            excelCell.font = { bold: true, size: 9, color: { argb: '262626' } };
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
            excelCell.border = {
              top: { style: 'medium', color: { argb: '9A9064' } },
              left: { style: 'medium', color: { argb: '9A9064' } },
              bottom: { style: 'medium', color: { argb: '9A9064' } },
              right: { style: 'medium', color: { argb: '9A9064' } }
            };
          }

          this.setStyles(cell, excelCell, isGrayBorderElement, isRowBgColor, isRowBgColorOne, isBlackBorderElement, bgColor)

          if (colspan && Number(colspan) > 1) {
            const mergeEnd = cellIndex + Number(colspan) - 1;
            worksheet.mergeCells(currentRow, cellIndex, currentRow, mergeEnd);
            cellIndex = mergeEnd + 1;
          } else {
            cellIndex++;
          }
        });
        currentRow++;
      });

      currentRow++;
    });
  }

  setYtdTable(isYtdMtdTable, excelCell, isAltWhtGryTable, isProfileGreyEven) {
    if (isYtdMtdTable) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F0D9D2' }
      };

    } else if (isAltWhtGryTable || isProfileGreyEven) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'F0F0F0' }
      };
    }
  }

  IsYtdTableLastChild(isYtdMtdTable, isLastChild, excelCell) {
    if (isYtdMtdTable && isLastChild) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EBE7B2' } // last child
      };
    }
  }

  setStyles(cell, excelCell, isGrayBorderElement, isRowBgColor, isRowBgColorOne, isBlackBorderElement, bgColor) {
    if (cell.classList.contains('dxfw-custom-report-table-header') || cell.classList.contains('dxfw-profile-header')) {
      excelCell.font = { bold: true, size: 9, color: { argb: '262626' } };
      excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
      excelCell.border = {
        top: { style: 'medium', color: { argb: '9A9064' } },
        left: { style: 'medium', color: { argb: '9A9064' } },
        bottom: { style: 'medium', color: { argb: '9A9064' } },
        right: { style: 'medium', color: { argb: '9A9064' } }
      };
    }

    if (cell.classList.contains('dxfw-national-dpr-header')) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'fffae5' }
      };
    }
    if (cell.classList.contains('dxfw-dealer-review-header')) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'C1B7CA' }
      };
    }
    if (cell.classList.contains('bg-color-yellow')) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'fffae5' }
      };
      excelCell.alignment = { vertical: 'middle', horizontal: 'left' };
    }
    if (isGrayBorderElement) {
      excelCell.border = {
        bottom: { style: 'thick', color: { argb: '808080' } }
      }
    }
    if (isRowBgColor) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'cfebe6' }
      };
    }
    if (isRowBgColorOne) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'ecc8bd' }
      };
    }
    if (isBlackBorderElement) {
      excelCell.border = {
        bottom: { style: 'thick', color: { argb: '000000' } }
      }
    }
    if (cell.classList.contains('left-align-column')) {
      excelCell.alignment = { vertical: 'middle', horizontal: 'left' };
    }
    if (bgColor === 'green' && !cell.classList.contains(matHeaderCell) && (cell.classList.contains('cdk-column-DEALERSHIP_MTD_EMPTY') || cell.classList.contains('mat-column-MEASURE_EMPTY'))) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '008000' }
      };
    }
    if (bgColor === 'red' && !cell.classList.contains(matHeaderCell) && (cell.classList.contains('cdk-column-DEALERSHIP_MTD_EMPTY') || cell.classList.contains('mat-column-MEASURE_EMPTY'))) {
      excelCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF0000' }
      };
    }

    const spanWithRedFont = cell.querySelector('span.dxfw-custom-report-table-cell-highlightcolor');
    if (spanWithRedFont) {
      excelCell.font = {
        color: { argb: 'FFFF0000' }
      };
    }
  }

  async createExcelKeyVals(worksheet, keyValueColumn, currentRow, filterValue = null) {
    const processCellValue = (key, value, filterValue?) => {
      if (key.toLowerCase() === 'dealer') {
        return filterValue && typeof filterValue === 'object' && 'MEMBER_NAME' in filterValue ? filterValue.MEMBER_NAME : filterValue;
      } else if (key.toLowerCase() === 'company' || key.toLowerCase() === 'hierarchy') {
        return value && typeof value === 'object' && 'name' in value ? value.name : (value != null ? value : '');
      }
      return value;
    };

    const processElement = (element, keyValueColumn, row, valueToUse, filterValue?) => {
      const cell = worksheet.getCell(row, keyValueColumn);
      const key = element.key.includes('month') ? 'Date' : element.key.charAt(0).toUpperCase() + element.key.slice(1);
      cell.value = `${key}: ${processCellValue(key, valueToUse, filterValue)}`;
      cell.font = { name: 'Arial', size: 9, bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'left' };
    };

    if (this.isTabbedReport) {
      this.searchObject.forEach(element => {
        processElement(element, keyValueColumn, 1, element.value); // Use element.value for tabbed reports
        keyValueColumn++;
      });
    } else {
      this.searchObject.forEach(element => {
        const valueToUse = filterValue ? filterValue : element.value;
        processElement(element, keyValueColumn, currentRow, element.value, valueToUse); // Use filter.value when available
        keyValueColumn++;
      });
    }
  }
}
