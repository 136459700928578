import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';

@Injectable({
  providedIn: 'root'
})

export class CustomReportsService {
  private readonly showPdfExcelIcons = new BehaviorSubject<any>(false);

  constructor(public toast: ToastService,) { }

  public handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          this.toast.openSnackBarFail(err, 'Close', 3000);
          this.spinnerService.hideSpinner();
          return throwError(err);
        })
      );
    };
  }

  public getPdfExcelIcons(): Observable<any> {
    return this.showPdfExcelIcons.asObservable();
  }

  public setPdfExcelIcons(flag: boolean): void {
    this.showPdfExcelIcons.next(flag);
  }
}