<div class="multiselect-dropdown dd-dropdown-filter-expansion">
  <mat-accordion class="filter-accordion" multi>
<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header class='filter-expansion-panel'>
    <mat-panel-title>
      <span class="filter-title">{{multiSelectFilterTitle}}</span>
      <div class="clear-container">
        <div
          class="filter-clear"
          (click)="onClear();$event.stopPropagation()">
          Clear
        </div>
        <div class="tmi icon tmi-arrow-half-right"></div>
      </div>
      </mat-panel-title>   
  </mat-expansion-panel-header>
  <mat-form-field appearance="outline" class="interval dd-mat-mdc-select-form-field">   
    <mat-select panelClass ='dd-mat-mdc-select-multiple' #select_1 [value]="selectedRows" placeholder="Select" multiple [ngClass]="isDropDownDisbaled ? 'disabled-select' :''"
      (openedChange)="onScrollChange($event,'select_1')">
      <mat-select-trigger *ngIf="selectedRows.length > 0">
        {{ getSelectedNames() }}
      </mat-select-trigger>
      <mat-form-field  class="search-input-field dd-mat-mdc-input-form-field">  
        <input matInput class="search-input mat-mdc-form-field-type-mat-input" placeholder="Type to search" [formControl]="searchTermInput" (keydown)="onKeyUp($event)">
        </mat-form-field>
      <button mat-icon-button class="close-button" *ngIf="selectedRows" (click)="closeMatSelect(select_1,$event)">
        <mat-icon>close</mat-icon>
      </button>
      <div class="select-header" *ngIf="headers.length>0">
        <span class="header-container">
          <span class="header-col-value" *ngFor="let header of headers">{{header}}</span>
        </span>
      </div>

      <mat-option (onSelectionChange)="change($event)" *ngFor="let row of paginatedRows" [value]="row">
        <div class="option-container">
          <span *ngFor="let col of columns" class="col-value">{{row[col] == "NULL" ? '' : row[col]}}</span>
        </div>
      </mat-option>

    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="requiredMsg && selectedRows.length === 0">{{requiredMsg}}</mat-error>
  <mat-error *ngIf="!requiredMsg && paginatedRows.length === 0">{{noDataMessage}}</mat-error>

  <ul class="search-chip" *ngIf="selectedRows.length > 0">
    <ng-container *ngFor="let row of selectedRows; let i = index">
      <li>
        {{row[this.chipFieldToShow]}}
        <em class="tmi tmi-close" (click)="removeSelection(i)"></em>
      </li>
    </ng-container>
  </ul>
</mat-expansion-panel>
  </mat-accordion>
</div>
