
<div class="dxfw-report-grid">
    <div class="dxfw-content-grid">
        <table class="report-grid dd-mat-mdc-table" [draggable]=false mat-table [dataSource]="reportDataSource"
        [ngClass]= "isCustomReport ? 'custom-table' : ''"  matSort aria-describedby="Report List">
            <ng-container matColumnDef="name">
                <th id="name" mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]=" isCustomReport ? 'display-class-hidden' : 'display-class-show' "  >Name</th>
                <td mat-cell *matCellDef="let element" (click)="rowClicked(element)" [ngClass]=" isCustomReport ? 'custom-cell-style' : '' " >
                    <span class="dxfw-word-wrap">
                        <span *ngIf="!element.isLegacyReport && !element?.url?.includes('dashboard-reports')">{{element.name ? element.name : '--'}}</span>
                         <a [href]="element.url" (click)="$event.preventDefault()" target="_self" *ngIf="!element.isLegacyReport && element?.url?.includes('dashboard-reports')">{{element.name ? element.name : '--'}}</a>
                  <a [href]="legacyUrl" *ngIf="element.isLegacyReport" target="_blank">{{element.name ? element.name : '--'}}</a>
                  <div class="hint-class"><mat-hint *ngIf="isCustomReport">description</mat-hint></div>
                </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="department">
                <th id="department" mat-header-cell *matHeaderCellDef mat-sort-header> Department </th>
                <td mat-cell *matCellDef="let element">
                    <span class="dxfw-word-wrap">
                        {{element.department ? element.department : '--'}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="frequency">
                <th id="frequency" mat-header-cell *matHeaderCellDef mat-sort-header> Frequency </th>
                <td mat-cell *matCellDef="let element">
                    <span class="dxfw-word-wrap">
                        {{element.frequency ? element.frequency : '--'}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="dataAsOf">
                <th id="dataAsOf" mat-header-cell *matHeaderCellDef mat-sort-header> Data As Of </th>
                <td mat-cell *matCellDef="let element">
                    <span class="dxfw-word-wrap">
                        <span>{{element.dataAsOf ? element.dataAsOf : '--'}}</span>
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="dateLastUse">
                <th id="lastdateOfUse" mat-header-cell *matHeaderCellDef mat-sort-header> Date Of Last Use </th>
                <td mat-cell *matCellDef="let element"> -- </td>
            </ng-container>

            <ng-container [matColumnDef]="'search-'+column.searchFieldName"
                *ngFor="let column of tableDef; let i = index">
                <th mat-header-cell *matHeaderCellDef class="dxfw-column-search" [ngClass]="column.searchFieldName==='name' ? 'search-input-width' : 'common-input-width' ">
                    <ng-container *ngIf="column.searchFieldType==='string'">
                        <input matInput type="text" placeholder="Search {{column.columnName}}"
                            [name]="column.searchFieldName" (keyup)="updateSearchValue(column.searchFieldName)"
                            [value]="searchParams[column.searchFieldName]"
                            [(ngModel)]="searchParams[column.searchFieldName]" [ngModelOptions]="{standalone: true}" class="input-padding"/>
                    </ng-container>

                    <ng-container *ngIf="column.searchFieldType==='select'">
                        <mat-select (selectionChange)="updateSearchValue(column.searchFieldName)"
                            panelClass="mat-primary dd-mat-mdc-select" placeholder="Choose {{column.columnName}}" 
                            [(value)]="searchParams[column.searchFieldName]" class="input-padding ">
                            <mat-option class="filter-option" *ngFor='let opt of column.searchFieldOptions;'
                                [value]="opt.value">{{opt.name}}
                            </mat-option>
                        </mat-select>
                    </ng-container>
                    <ng-container *ngIf="column.searchFieldType==='multiselect'">
                        <mat-select (selectionChange)="updateSearchValue(column.searchFieldName)"
                            panelClass="mat-primary dd-mat-mdc-select-multiple" placeholder="Choose {{column.columnName}}"
                            [(value)]="searchParams[column.searchFieldName]" class="input-padding" multiple>
                            <mat-option class="filter-option" *ngFor='let opt of column.searchFieldOptions;'
                                [value]="opt.value">{{opt.name}}
                            </mat-option>
                        </mat-select>
                    </ng-container>

                    <ng-container *ngIf="column.searchFieldType==='calendar'">
                        <input matInput [matDatepicker]="picker" placeholder="Choose {{column.columnName}}"
                            (dateChange)="updateSearchValue(column.searchFieldName)"
                            [(ngModel)]="searchParams[column.searchFieldName]" [ngModelOptions]="{standalone: true}" class="input-padding">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </ng-container>
                </th>
            </ng-container>

            <tr mat-header-row [ngStyle]="{'display': isCustomReport ? 'none' : '' }" *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-header-row [ngStyle]="{'display': isCustomReport ? 'none' : '' }" *matHeaderRowDef="displayedSearches; sticky: true" class="row-column-search"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" 
            [ngClass]= "isCustomReport ? ' custom-table-row' : 'row-title'"></tr>
            <tr class="mat-row" *matNoDataRow class="no-data-row">
                <td class="mat-cell text-center p-0 border-0" [attr.colspan]="displayedColumns.length" class="no-data">
                    <div *ngIf='!isCustomReport' class="mb-2">No Records Found</div>
                    <div *ngIf='isCustomReport' class="dashboard-error-msg">No Dashboards are available for your view</div>
                    <button *ngIf='!isCustomReport' class="primary-button clear-button" (click)="removeAllFilters()">
                        Clear Filters
                    </button>
                </td>
            </tr>
        </table>

        <div class="content-grid-bottom"> </div>

    </div>

    <div class="dxfw-content-bottom"></div>
</div>
