<div class="toyota_dealer_glance">
  <div class="tab-container dd-mat-mdc-tab-container">
  <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters to view the report</div>
  <mat-tab-group *ngIf="selectedFilter?.length > 0"(focusChange)="tabChange($event.index)">
    <div *ngIf="toyotaDealerAtGlancejsonObj?.tabList">
      <mat-tab #tabContent *ngFor="let tab of toyotaDealerAtGlancejsonObj?.tabList">
        <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
        <div
          *ngFor="let currdataSource of toyotaDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource; let k=index">
            <app-multi-header-table [tableIndex]="k" *ngIf="!isProfileTable"
              [multiHeaderTableData]='currdataSource' [reportId]="reportId">
            </app-multi-header-table>
            <div #pdfElement *ngIf="isProfileTable" class='dxfw-content-table-container'>
              <app-generic-profile-table [tabIndex]="k" [genericProfileTableData]='currdataSource' [isTcuv]="true"
                [deptTdaag]="true"></app-generic-profile-table>
            </div>
        </div>
        <div class="dxfw-customreport-no-data-label"
        *ngIf="toyotaDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 && selectedFilter?.length !== 0">
        Sorry, there are no results that match your search criteria.</div>
        <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div>
      </mat-tab>
    </div>
  </mat-tab-group>
</div>
</div>