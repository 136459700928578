import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { reportId } from 'src/app/constants/constants';
import { PartDepartmentModelComponent } from '../../reports/part-department-model/part-department-model.component';
import { DisplayValues } from '../../reports/reportConstants';
import { CustomReportsService } from '../../services/custom-report.service';
import * as _ from 'lodash';


@Component({
  selector: 'app-multi-header-table',
  templateUrl: './multi-header-table.component.html',
  styleUrls: ['./multi-header-table.component.scss']
})
export class MultiHeaderTableComponent implements OnInit {
  @Input() multiHeaderTableData;
  @Input() reportId;
  @Input() headerData;
  @Input() tableIndex;
  @Input() selectedDateFilter;
  public multiHeaderTabledataSource;
  public multiHeaderdisplayedColumns: string[] = [];
  public tableSubHeaderColumns: string[] = [];
  public tableSubHeaderColumns2: string[] = [];
  public subHeaderColumns: string[] = [];
  public tableFirstHeader;
  public tableFirstHeaderColumns: string[] = [];
  public tableSecondHeader;
  public tableSecondHeaderColums: string[] = [];
  public subHeaderColumns2: string[] = [];
  public obj = Object.keys;
  public isRegionalTable: boolean = false;
  public displayColumnName: string[] = [];
  public tableHeaderClass: string = '';
  public tableSubHeaderBGColorClass: string = '';
  public tableColumnClass: string = '';
  public sectionTitle: string = '';
  public totolColumns: number;
  public hideColumnHeader: boolean = false;
  public sectionId;
  public rowAdded: boolean = false;
  public showRowBorder: boolean = false;
  public alternateRowColorClass: string = '';
  public isMatTableClass: boolean = false;
  public showEmptyCol: boolean = false;
  public printZeros: boolean = false;
  public whiteBorderClass: string = '';
  defaultUnitFlag: any;
  removeParentsForNegativeVal: boolean = false;
  dataFormattingColumns: any = [];
  isDisplayedColumns: any;
  isReduceSColumnWidth: any = [];
  isReduceSColumnWidthPixels: any = [];
  isFixedMeasureColumnWidth: any = [];
  tableSubHeaderWhiteBGColorClass: string = '';
  allGuidlinesMet: string;
  istcuvPerfSessionWiseFormula: string;
  subHeaderForYearTag: string;
  showNegativeInRed: boolean = false;
  defaultColumnsUnitFlag: any = [];
  isApplyBgColorOnRow: any = [];
  columnsForComparisonToShowInRed: any = [];
  widthClass: any;
  setStylesForColumns: any = [];

  constructor(private readonly customReportsService: CustomReportsService,
    private dialogue: MatDialog) {

  }


  ngOnInit(): void {
    // regional table for styling effect
    this.isRegionalTable = this.multiHeaderTableData?.isRegionalTable;
    this.sectionTitle = this.multiHeaderTableData?.sectionTitle;
    this.allGuidlinesMet = this.multiHeaderTableData?.allGuidLinesMet;
    this.sectionId = this.multiHeaderTableData?.sectionId;
    this.headerData = this.multiHeaderTableData?.headerData;
    this.hideColumnHeader = this.multiHeaderTableData?.hideColumnHeader;
    this.defaultUnitFlag = this.multiHeaderTableData?.defaultUnitFlag;
    this.dataFormattingColumns = this.multiHeaderTableData?.dataFormattingColumns;
    this.removeParentsForNegativeVal = this.multiHeaderTableData?.removeParentsForNegativeVal;
    this.printZeros = this.multiHeaderTableData?.printZeros;
    this.showRowBorder = this.multiHeaderTableData?.showRowBorder;
    this.isReduceSColumnWidth = this.multiHeaderTableData?.isReduceSColumnWidth;
    this.isReduceSColumnWidthPixels = this.multiHeaderTableData?.isReduceSColumnWidthPixels;
    this.isFixedMeasureColumnWidth = this.multiHeaderTableData?.isFixedMeasureColumnWidth;
    this.alternateRowColorClass = this.multiHeaderTableData?.alternateRowColorClass;
    this.isMatTableClass = this.multiHeaderTableData?.isMatTableClass;
    this.showEmptyCol = this.multiHeaderTableData?.showEmptyCol;
    this.whiteBorderClass = this.multiHeaderTableData?.whiteBorderClass
    this.istcuvPerfSessionWiseFormula = this.multiHeaderTableData?.istcuvPerfSessionWiseFormula;
    this.subHeaderForYearTag = this.multiHeaderTableData?.subHeaderForYearTag;
    this.showNegativeInRed = this.multiHeaderTableData?.showNegativeInRed;
    this.defaultColumnsUnitFlag = this.multiHeaderTableData?.defaultColumnsUnitFlag;
    this.isApplyBgColorOnRow = this.multiHeaderTableData?.isApplyBgColorOnRow;
    this.columnsForComparisonToShowInRed = this.multiHeaderTableData?.columnsForComparisonToShowInRed;
    this.widthClass = this.multiHeaderTableData?.widthClass;
    this.setStylesForColumns = this.multiHeaderTableData?.setStylesForColumns;

    //altered columns names for each table
    if (this.multiHeaderTableData?.columnHeaderName) {
      this.displayColumnName = this.multiHeaderTableData?.columnHeaderName ? this.multiHeaderTableData?.columnHeaderName : [];

    }
    this.getReportStructure();
    this.tableHeaderClass = this.multiHeaderTableData?.tableHeaderClass;
    this.tableSubHeaderBGColorClass = this.multiHeaderTableData?.tableSubHeaderBGColorClass;
    this.tableSubHeaderWhiteBGColorClass = this.multiHeaderTableData?.tableSubHeaderWhiteBGColorClass;
  }

  public getReportStructure() {
    const tableData = this.multiHeaderTableData?.tableData;
    this.subHeaderColumns = this.multiHeaderTableData?.tableSubHeader;
    this.tableFirstHeader = this.multiHeaderTableData?.tableHeader;
    this.tableSecondHeader = this.multiHeaderTableData?.tableHeader;
    this.subHeaderColumns2 = this.multiHeaderTableData?.tableSubHeader2;
    this.isDisplayedColumns = this.multiHeaderTableData?.isDisplayedColumns;

    const isReportDataSource = !this.isPartsAndServiceDeptReport() && !this.isSalesAnalysisReport() && !this.isNationalCompositeUsedAndNewDprAnalysisReport() && !this.isDealerPerformanceReport && this.multiHeaderTableData.datasourceName !== 'tcuvfinance' && this.reportId !== reportId.TOYOTA_DEALER_GLANCE && this.reportId !== reportId.PS_FINANCIAL
      && this.reportId !== reportId.LEXUS_SALES_GLANCE && this.multiHeaderTableData.datasourceName !== 'parts_access_data' && this.multiHeaderTableData.datasourceName !== 'parts_data' && this.reportId !== reportId.LEXUS_DEALER_GLANCE && this.reportId !== reportId.DEALER_REVIEW && this.isDisplayedColumns === true;

    if (tableData && tableData[0]) {
      if (isReportDataSource) {
        this.multiHeaderdisplayedColumns = Object.keys(tableData[0]);
      } else {
        this.multiHeaderdisplayedColumns = this.getDisplayedColumns(tableData);
      }
      this.multiHeaderdisplayedColumns = this.mapRegionNameToStart(this.multiHeaderdisplayedColumns, "REGION_NAME")
      this.multiHeaderdisplayedColumns = this.mapRegionNameToStart(this.multiHeaderdisplayedColumns, "AREA")
    }
    if (this.displayColumnName && this.displayColumnName?.length > 0) {
      this.displayColumnName = this.mapRegionNameToStart(this.displayColumnName, "")
    }

    if (this.subHeaderColumns && this.subHeaderColumns.length > 0) {
      const nestedSubHeaderSet = new Set<string>(); // to avoid duplicates
      this.subHeaderColumns?.forEach((subHeader, index) => {
        const mainHeaderKeys = Object.keys(subHeader);
        mainHeaderKeys?.forEach(mainHeader => {
          nestedSubHeaderSet.add(mainHeader);
        });
      });
      this.tableSubHeaderColumns = Array.from(nestedSubHeaderSet);
      const nestedSubHeaderSet2 = new Set<string>();

      this.subHeaderColumns2?.forEach((subHeader, index) => {
        const mainHeaderKeys = Object.keys(subHeader);
        mainHeaderKeys?.forEach(mainHeader => {
          nestedSubHeaderSet2.add(mainHeader);
        });
      });
      this.tableSubHeaderColumns2 = Array.from(nestedSubHeaderSet2);
    }

    if (this.tableFirstHeader && this.tableFirstHeader[0]) {
      this.tableFirstHeaderColumns = Object.keys(this.tableFirstHeader[0]);
    }
    if (this.tableSecondHeader && this.tableSecondHeader[0]) {
      this.tableSecondHeaderColums = Object.keys(this.tableSecondHeader[0]);
    }

    if (this.multiHeaderTableData.datasourceName === 'source_of_sale' || this.multiHeaderTableData.datasourceName === 'series_report' || this.multiHeaderTableData.datasourceName === 'aged_units') {
      this.tableColumnClass = 'dxfw-custom-report-table-cell-color';

    } else if (this.multiHeaderTableData?.showLineNumbers) {
      this.tableColumnClass = 'dxfw-custom-report-table-cell-center-align';

    } else {
      this.tableColumnClass = 'dxfw-custom-report-table-cell-left-align';
    }
    this.multiHeaderTabledataSource = new MatTableDataSource(tableData);

  }

  public mapRegionNameToStart(array, name) {
    const indexRegion = array.indexOf(name)
    if (indexRegion !== -1) {
      const replaceRegion = array.splice(indexRegion, 1)[0];
      array.unshift(replaceRegion)
    }
    return array
  }

  public transformText(element, col) {
    if (["T_CHANGE", "C_CHANGE"].includes(col)) {
      return element + '%';
    }
    return element;
  }

  public displayCustomizedHeaderName(ele) {
    if (DisplayValues[ele]) {
      return DisplayValues[ele]
    } else if (ele === 'DIST_GP_CY' || ele === 'DIST_GP_PY' || ele === 'DLR_GP_CY' || ele === 'DLR_GP_PY' || ele === 'NTNL_GP_CY' || ele === 'NTNL_GP_PY' || ele === 'RGN_GP_CY' || ele === 'RGN_GP_PY') {
      if ((this.sectionId == 1 || this.sectionId == 5) && this.reportId === reportId.PARTS_DEPARTMENT) {
        return '';
      } else if (this.reportId === reportId.SERVICE_DEPARTMENT) {
        return '%GP';
      } else {
        return 'GP % Sales';
      }
    } else if (ele === 'ACCT_NAME' || ele === 'ACCT_TITLE') {
      return this.sectionTitle;
    }
    else if (ele === 'ROWTYPE') {
      return this.sectionTitle;
    } else if (this.reportId === reportId.LEXUS_DEALER_GLANCE && ele === this.sectionTitle) {
      return this.selectedDateFilter;
    } else if (ele === 'MEASURE_EMPTY' || ele === 'DEALERSHIP_MTD_EMPTY' || ele === 'KEY_METRIC_EMPTY' || ele === 'STEP' || ele === 'OPERATOR' || ele === 'Line#') {
      return '';
    } else if (this.reportId === reportId.DEALER_REVIEW && ele === 'YEAR_TAG') {
      return this.subHeaderForYearTag;
    } else if (this.reportId === reportId.TCUV_PERFORMANCE_CARD && (ele === 'SYMBOL' || ele === 'DEALER_MTD_IND' || ele === 'DEALER_YTD_IND')) {
      return '';
    }
    else {
      return ele
    }

  }
  openModel() {
    this.dialogue.open(PartDepartmentModelComponent, {
      width: '620px',
      height: '400px'
    })
  }

  checkDifferenceColValue(columnName: string, ele: any) {
    if (this.showNegativeInRed && ele[columnName] !== null && ele[columnName] !== undefined) {
      if (_.isString(ele[columnName]) && ele[columnName].endsWith('%') && !this.columnsForComparisonToShowInRed) {
        const value = ele[columnName]?.split('%');
        return value[0] < 0;
      } else if (this.columnsForComparisonToShowInRed) {
        const compareObj = this.columnsForComparisonToShowInRed?.find(col => col.firstCol === columnName);
        if (compareObj) {
          return Number(ele[compareObj.firstCol]) < Number(ele[compareObj.secondCol]);
        } else {
          return ele[columnName] < 0
        }
      } else {
        return ele[columnName] < 0
      }
    }
    else {
      return (this.showNegativeInRed && ele.SEL_MTH_FONT_CLR && columnName === 'SEL_MTH_CY' && this.multiHeaderTableData?.showIndicator);
    }
  }

  checkNullValOfGPColumm(col: string, ele: any) {
    if (this.reportId === reportId.SERVICE_DEPARTMENT && col.includes('_GP') && (ele.ACCT_NO_GP.includes('NA') || ele.ACCT_NO_GP === '')) {
      return true;
    } else if (this.reportId === reportId.PARTS_DEPARTMENT && col.includes('_GP') && ele[col] === null && ele.IS_USER_INPUT === false && ele.ACCT_NO_GP === null && (this.sectionId != 1 && this.sectionId != 5)) {
      return true;
    } else if (this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY && this.multiHeaderTableData?.showIndicator && (((col === 'NEW_CURR_MTD' || col === 'NEW_CURR_YTD' || col === 'NEW_LAST_YTD') && ele.NEW_CLR_FLAG === 1)
      || ((col === 'COMB_CURR_MTD' || col === 'COMB_CURR_YTD' || col === 'COMB_LAST_YTD') && ele.COMB_CLR_FLAG === 1) || ((col === 'USED_CURR_MTD' || col === 'USED_CURR_YTD' || col === 'USED_LAST_YTD') && ele.USED_CLR_FLAG === 1))) {
      return true;
    }
    else {
      return false;
    }
  }
  public displayCustomizedFirstHeader(ele) {

    if (ele === 'NO-NAME') {
      return '';
    } else {
      return ele;
    }
  }
  CustomizedBorder(i) {
    if (this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY && (i === 1 || i === 2 || i == 3)) {
      return 'border-right'
    } else {
      return '';
    }
  }

  displayingInput(col: string, ele: any) {
    return (ele?.IS_USER_INPUT === true && col != 'Line#' && col != 'ACCT_NAME') || (ele[col] === "NULL" && (col === 'TOTAL_SOLD' || col === 'ONE_VISIT_SOLD' || col === 'TWO_VISIT_SOLD'));
  }

  public getDisplayedColumns(tableData) {
    let hideColumns = ['IS_USER_INPUT', 'SECTIONID', 'SECTIONLINENO', 'ACCT_NO', 'ACCT_NO_GP', 'SECTIONTITLE', 'SECTIONNO', 'FONTCOLOR', 'DEALER_CODE', 'DISTRICT_CODE', 'ACCTSEQNO', 'UNITS_FLAG', 'REGION_CODE', 'ACCT_MONTH', 'ACCTSEQNO', 'DEALER', 'ACCT_YEAR',
      'X_FLAVOUR_IND', 'X_ITEMSEQNO', 'X_ROW_BG_COLOR', 'X_UNIT_FLG_KEY', 'X_ACCT_NO', 'X_FONTCLASS', 'X_ITEM_NBR', 'X_SEQ', '04080', 'LA - A - 04080 - PENSKE TOYOTA', 'X_TYPE_RCID', 'X_DATA_AS_OF_DTE'
      , 'X_SECTION_TITLE_TXT', 'X_ENGINE_MEETS_GUIDE_FLG', 'X_DRIVETRAIN_MEETS_GUIDE_FLG', 'X_CHASSIS_MEETS_GUIDE_FLG', 'X_ELECTRICAL_MEETS_GUIDE_FLG', 'X_HYBRID_MEETS_GUIDE_FLG', 'X_DISPLAY_SEQ', 'X_LEVEL_DISPLAY_SEQ', 'X_ROWTYPE_DISPLAY_SEQ', 'X_UNIT_FLAG_KEY', 'LSAAG_X_UNIT_FLAG_KEY',
      'NEW_CLR_FLAG', 'USED_CLR_FLAG', 'COMB_CLR_FLAG', 'ACCTMO', 'ROW', 'ACCTNO', 'LINE#', 'ACCTYR', 'CHANGECALC', 'DATALOADED', 'FORMATTYPE', 'SUBRPTID', 'SUBRPTTITLRFROMREPACCTS', 'SEQNO', 'DISTNO', 'REGIONID', 'HIERARCHY', 'FONTCOLOR', 'ENGINE_MEETS_GUIDE_FLG', 'DRIVETRAIN_MEETS_GUIDE_FLG', 'CHASSIS_MEETS_GUIDE_FLG',
      'ELECTRICAL_MEETS_GUIDE_FLG', 'HYBRID_MEETS_GUIDE_FLG', 'DATA_AS_OF_DTE', 'ROW_TITLE_TXT', 'ACC_NO', 'SECTIONSEQNO', 'FONTCLASS', 'ITEM_NBR', 'ROW_BG_COLOR', 'SEL_MTH_FONT_CLR', 'UNIT_FLAG_KEY', 'SEQ_NO', 'DLR_SA_FLAG', 'DLR_DS_FLAG', 'FLAVOR_IND', 'GUIDE_LINES_MET', 'SECTIONKEY'
    ];
    if ((this.reportId === reportId.TOYOTA_DEALER_GLANCE && this.multiHeaderTableData?.hideColumns?.length > 0) ||
      (this.reportId === reportId.TCUV_PERFORMANCE_CARD && this.multiHeaderTableData?.hideColumns?.length > 0)) {
      hideColumns = hideColumns.concat(this.multiHeaderTableData?.hideColumns);
    }
    const data = Object.keys(tableData[0])?.filter(((ele: any) => {
      if (!hideColumns.includes(ele)) {
        return ele;
      }
    }));
    if (this.multiHeaderTableData?.showLineNumbers) {
      data.unshift('Line#');
    }
    this.totolColumns = data.length;
    return data;
  }

  showBorder(col, i) {
    if ((i === 0 && this.isPartsAndServiceDeptReport()) || (i === 4 && this.isDealerReviewReport() && col === 'DEADLINES_EMPTY')) {
      return 'column-0';
    } else if (i === 0 && this.isSalesAnalysisReport()) {
      return 'column-1'
    } else if ((col?.includes('_DIFF') || this.multiHeaderTableData?.isColumnBorder?.includes(col)) && i !== this.totolColumns - 1) {
      return 'border-right';
    } else if (this.multiHeaderTableData?.isfixedColumnWidthBorder?.includes(col)) {
      return 'column-width'
    } else if (col?.includes('ACCT_NAME') || col?.includes('FINANCE_INFO')) {
      return 'column-1';
    } else if (i === 0 && (this.isNationalCompositeUsedAndNewDprAnalysisReport() || this.isDealerPerformanceReport() || this.isDealerPerformanceNewUsedCombinedReport())) {
      return 'column-a';
    } else if (i === 1 && (this.isNationalCompositeUsedAndNewDprAnalysisReport() || this.isDealerPerformanceNewUsedCombinedReport()) || (i === 2 && this.isDealerReviewReport() && col === 'PROVISIONS')) {
      return 'column-b';
    } else if (i === 1 && this.isDealerPerformanceReport()) {
      return 'dealer-review-class';
    } else if (this.isReduceSColumnWidth?.includes(col)) {
      return 'column-d'
    } else if (this.isFixedMeasureColumnWidth?.includes(col)) {
      return 'column-measure-width'
    } else if (this.isReduceSColumnWidthPixels?.includes(col)) {
      return 'column-e'
    } else if (i === 0 && this.isLexusDaag()) {
      return 'column-c';
    } else {
      return 'column'
    }
  }
  getColumnStyle(col) {
    if (this.setStylesForColumns) {
      const obj = this.setStylesForColumns.find(x => x.columns?.includes(col));
      if (obj) {
        return obj.styles;
      } else {
        const obj = this.setStylesForColumns.find(x => x.columns === 'default');
        return obj?.styles;
      }
    }
  }

  showColumnBorder(i) {
    if (this.multiHeaderTableData?.columnBorders?.includes(i) && this.reportId === reportId.TOYOTA_DEALER_GLANCE) {
      return 'border-right';
    } else {
      return '';
    }
  }
  showBorderBottom(row, i) {
    if (row?.FORMATTYPE === 342) {
      return 'border-bottom-gray';
    } else if (row?.FORMATTYPE === 343) {
      return 'border-bottom-black';
    } else if (this.multiHeaderTableData?.rowShowBorderBottom?.includes(i)) {
      return 'border-bottom-gray';
    } else if (this.isApplyBgColorOnRow?.includes(i) && i === 3) {
      return 'row-green-bgcolor';
    } else if (this.isApplyBgColorOnRow?.includes(i) && i === 4) {
      return 'row-pink-bgcolor';
    } else {
      return '';
    }
  }

  isPartsAndServiceDeptReport() {
    return (this.reportId === reportId.SERVICE_DEPARTMENT || this.reportId === reportId.PARTS_DEPARTMENT);
  }

  isSalesAnalysisReport() {
    return (this.reportId === reportId.SALES_ANALYSIS_NEW_VEHICLE || this.reportId === reportId.SALES_ANALYSIS_USED_VEHICLE || this.reportId === reportId.SERVICE_ANALYSIS || this.reportId === reportId.PARTS_ANALYSIS);
  }

  isNationalCompositeUsedAndNewDprAnalysisReport() {
    return (this.reportId === reportId.NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT || this.reportId === reportId.TFS_NATIONAL_COMPOSITE_COMBINED_USED_NEW_DEPT);
  }

  isDealerReviewReport() {
    return (this.reportId === reportId.DEALER_REVIEW);
  }
  isDealerPerformanceReport() {
    return (this.reportId === reportId.DEALER_PERFORMANCE_REPORT_FINANCIAL_SERVICES_SUMMARY);
  }
  isDealerPerformanceNewUsedCombinedReport() {
    return (this.reportId === reportId.DEALER_PERFORMANCE_REPORT_NEW_USED_COMBINED);
  }
  isLexusDaag() {
    return (this.reportId === reportId.LEXUS_DEALER_GLANCE)
  }


  showBgColor(val) {
    if (val === 'G') {
      return 'green'
    } else {
      return '';
    }
  }

  getBackgroundColor(col, element) {
    if (col === 'MEASURE_EMPTY' || col === 'DEALERSHIP_MTD_EMPTY' || col === 'KEY_METRIC_EMPTY') {
      return element.FONTCOLOR
    } else if (element[col] === "G" && this.reportId === reportId.TCUV_PERFORMANCE_CARD) {
      return 'green';
    } else if (element[col] === 'R' && this.reportId === reportId.TCUV_PERFORMANCE_CARD) {
      return 'red';
    }
  }
  formatValWithStar(item, col) {
    if (item[col] !== null && (item['FLAVOR_IND'] === 'Avg' || item['SECTIONKEY'] === 2) && this.defaultColumnsUnitFlag[0].starColumns?.includes(col)) {
      return '**';
    } else {
      return '';
    }
  }

}

