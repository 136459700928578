import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { COMPANY_TYPE, HIERARCHY_TYPE, REPORT_MSGS, reportId, reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getImageFromBase64, getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import {
  IMAGE_CONTENT
} from 'src/app/constants/imageContent'

@Component({
  selector: 'app-national-dealer-performance-financial-services',
  templateUrl: './national-dealer-performance-financial-services.component.html',
  styleUrls: ['./national-dealer-performance-financial-services.component.scss']
})
export class NationalDealerPerformanceFinancialServicesComponent implements OnInit {

  public currentTabIndex: number = 0;
  public selectedFilter = [];
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  public nationalDealerPerformanceFinancialServicesjsonObj;
  public sources: Observable<any>[] = [];
  public obj = Object.values;
  public selectedYear;
  // selectedDealerCode: string;
  public noDataToDisplayMsg: string;
  public reportTitleToDisplay: string;
  public reportId: number;
  public companyCode: number;
  public hierarchy: number;
  public dealerId: number;
  public dealerName: number;
  public imgPath: string;
  public rightLogoPath: string;
  public reportTitle: string = '';
  public IsDealerHierarchy: boolean = false;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private customerReportService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportId = reportUrlType[reportTypeNameFromUrl];
    const reportList = JSON.parse(localStorage.getItem('report-name'))
    this.reportTitle = reportList?.[this.reportId];
    this.getNationalDealerPerformanceFinancialServicesReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
  }


  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }
  public getPathforLogo() {
    if (this.companyCode === COMPANY_TYPE.TOYOTA && this.hierarchy === HIERARCHY_TYPE.DEALER) {
      this.rightLogoPath = IMAGE_CONTENT.TOYOTA_QUALITY_FINANCIAL_MANAGEMENT
    } else if (this.companyCode === COMPANY_TYPE.LEXUS && this.hierarchy === HIERARCHY_TYPE.DEALER) {
      this.rightLogoPath = IMAGE_CONTENT.LEXUS_QUALITY_FINANCIAL_MANAGEMENT;
    }
  }
  public getTabDataSource() {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.companyCode = obj['company'].id;
    this.hierarchy = obj['hierarchy'].id;
    this.dealerId = obj['dealer'].MEMBER_CODE;
    this.dealerName = obj['dealer'].MEMBER_NAME;
    const dateObj = getMonthAndYear(obj['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      view_id: 'dealer_performance',
      comp_code: obj['company'].id,
      hierarchy: obj['hierarchy'].id,
      hierarchy_member_code: obj['dealer'].MEMBER_CODE,
    }
    if (obj['hierarchy']?.id === HIERARCHY_TYPE.DEALER) {   // 10 - Dealer 
      this.IsDealerHierarchy = true;
      this.reportTitleToDisplay = obj['dealer'].MEMBER_NAME + ' - ' + obj['dealer'].MEMBER_CODE;
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.DISTRICT) {  // 11 - District
      this.reportTitleToDisplay = obj['dealer'].MEMBER_NAME
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.REGION) {  // 12 - Region
      this.reportTitleToDisplay = obj['dealer'].MEMBER_NAME + ' - ' + obj['dealer'].REGION_CODE
    } else if (obj['hierarchy']?.id === HIERARCHY_TYPE.NATIONAL) {   // 13 - National
      this.reportTitleToDisplay = 'TOYOTA FINANCIAL SERVICES';
    }

    this.sources = [this.customReportsApiService.getNationalDealerPerformanceFinancialServicesData(requestObj).pipe(this.customerReportService.handleError()?.bind(this))
    ]
    return this.sources;
  }

  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          if (filteredValues?.length > 0) {
            this.spinnerService.displaySpinner();
            this.selectedFilter = filteredValues;
            this.mapResponse();
          }

        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription.unsubscribe();
    this.customerReportService.setPdfExcelIcons(false);
  }

  public getNationalDealerPerformanceFinancialServicesReport() {
    this.nationalDealerPerformanceFinancialServicesjsonObj = {
      multiDataSources: true,
      datasource: []

    }
  }

  public async mapResponse() {
    let currentTableResponse = [];
    let tableSubHeaderColumns = [];
    await this.getTabDataSource();
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (resp.length > 0 && resp[0].status === 200) {
          this.customerReportService.setPdfExcelIcons(true);
          this.imgPath = getImageFromBase64(this.companyCode);
          this.getPathforLogo();
          tableSubHeaderColumns = this.getTableSubHeaderColumns();
          let reportData = resp[0]?.body;
          if (reportData?.length > 0) {
            reportData.forEach((ele, index) => {
              if (ele.sectionData?.length > 0) {
                const obj = [{
                  "datasourceName": `DealerPerformanceDatasource ${index}`,
                  "tableData": ele.sectionData,
                  "sectionTitle": ele.SECTIONTITLE,
                  "sectionId": ele.ROW,
                  "removeLineBreak": true,
                  'showLineNumbers': true,
                  'showIndicator': this.hierarchy === HIERARCHY_TYPE.DEALER ?  true: false,
                  'dateFormattingColumns': 'all',
                  'isColumnBorder':['NEW_CURR_MTD','USED_CURR_MTD']


                }];
                if (index === 0) {
                  obj[0]['tableHeader'] = tableSubHeaderColumns;
                }
                currentTableResponse.push(obj);
              }

            });
          }
          else {
            this.noDataToDisplayMsg = REPORT_MSGS.NO_RECORDS_FOUND;
          }
        }


        this.nationalDealerPerformanceFinancialServicesjsonObj.datasource = currentTableResponse;

        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }

  public getTableSubHeaderColumns() {
    return [{
      " ": '1',
      "NO-NAME": '1',
      "NEW VEHICLE": '3',
      "USED VEHICLE": '3',
      "COMBINED VEHICLE": '3'
    }]
  }

}



